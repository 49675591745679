const authProvider = {
  login: () => {
    return Promise.resolve();
  },
  logout: async () => {
    document.cookie =
      "token=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      return Promise.reject({ redirectTo: "/admin/sesionExpirada" });
    } else {
      if (status === 413) {
        throw new Error("El archivo debe pesar menos de 50MB.");
      }
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
