import React, { Component } from "react";
import calcularPx from "./calcularPx";
import { ReactComponent as Logo } from "../../res/iconos/logoGris.svg";
import { ReactComponent as IcoInstagram } from "../../res/iconos/instagram.svg";
import { ReactComponent as IcoFacebook } from "../../res/iconos/facebook.svg";
import { ReactComponent as IcoMail } from "../../res/iconos/mail.svg";

const Footer = () => {
  const mobile = window.innerWidth < 600;
  return (
    <React.Fragment>
      <div style={{ height: calcularPx(30), width: "100%" }} />
      <div
        style={{
          height: calcularPx(1),
          width: "100%",
          backgroundColor: "#C4C4C4",
        }}
      />
      {!mobile && (
        <Logo
          width={calcularPx(166.7)}
          height={calcularPx(103.1)}
          style={{ position: "absolute", right: "3.44%" }}
        />
      )}

      <div style={{ height: calcularPx(48), width: "100%" }} />

      <div style={{ display: "flex" }}>
        <div>
          <h1
            className="tituloBottom"
            style={{
              marginLeft: "5.73vw",
              marginBottom: calcularPx(34),
            }}
          >
            Redes Sociales
          </h1>

          <a
            href="https://www.instagram.com/emegearg"
            target="_blank"
            style={{
              display: "block",
              marginLeft: "5.73vw",
              marginBottom: calcularPx(6),
            }}
          >
            <IcoInstagram
              width={calcularPx(20)}
              height={calcularPx(20)}
              style={{ display: "inline-block" }}
            />
            <h1
              className="linkBottom"
              style={{ display: "inline-block", paddingLeft: calcularPx(12) }}
            >
              Instagram
            </h1>
          </a>

          <a
            href="https://www.facebook.com/people/Emeg%C3%A9-Calefacci%C3%B3n/100052003101632/?ref=page_internal"
            target="_blank"
            style={{
              display: "block",
              marginLeft: "5.73vw",
              marginBottom: calcularPx(6),
            }}
          >
            <IcoFacebook
              width={calcularPx(20)}
              height={calcularPx(20)}
              style={{ display: "inline-block" }}
            />
            <h1
              className="linkBottom"
              style={{ display: "inline-block", marginLeft: calcularPx(12) }}
            >
              Facebook
            </h1>
          </a>
        </div>
      </div>

      <div style={{ height: calcularPx(48), width: "100%" }} />

      <div
        style={{
          height: calcularPx(1),
          width: "100%",
          backgroundColor: "#C4C4C4",
        }}
      />
      <h1
        className="linkBottom"
        style={{
          paddingTop: calcularPx(36),
          paddingBottom: calcularPx(14),
          textAlign: "center",
        }}
      >
        Emegé 2024
      </h1>
    </React.Fragment>
  );
};

export default Footer;
