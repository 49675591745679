import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = "/api/admin";
const httpClient = fetchUtils.fetchJson;
const fileToDataUrl = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

const fileToBase64 = (blob) => fileToDataUrl(blob).then((text) => text);

const filePathAFile = async (paramsData) => {
  let data = {
    ...paramsData,
  };
  for (let p in data) {
    if (p.includes("Path") && typeof data[p] === "string") {
      let res = await fetch(data[p]);
      let blob = await res.blob();
      data[p] = {
        rawFile: new File([blob], data[p].split("/").slice(-1)),
        src: URL.createObjectURL(blob),
      };
    }
  }
  return data;
};

const fileABack = async (params) => {
  let data = {
    ...params,
  };
  for (let p in data) {
    if (p.includes("Path") && typeof data[p] === "object") {
      let nombre = data[p].rawFile.name;
      let archivo = await fileToBase64(data[p].rawFile);
      data[p] = {
        nombre: nombre,
        archivo: archivo,
      };
    }
  }
  return data;
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(async ({ headers, json }) => {
      let data = [...json];
      let i = 0;
      while (i < data.length) {
        data[i] = await filePathAFile(data[i]);
        i++;
      }
      return {
        data: data,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    });
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      async ({ json }) => {
        let data = await filePathAFile(json);
        return {
          data: data,
        };
      }
    );
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(async ({ json }) => {
      let data = [...json];
      let i = 0;
      while (i < data.length) {
        data[i] = await filePathAFile(data[i]);
        i++;
      }
      return {
        data: data,
      };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  create: async (resource, params) => {
    let data = await fileABack(params.data);
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({
      data: { data, id: json.id },
    }));
  },

  update: async (resource, params) => {
    let data = await fileABack(params.data);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: async (resource, params) => {
    let data = await fileABack(params.data);
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
