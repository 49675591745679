import { ImageField } from "react-admin";

export const CustomImageField = ({ record }) => {
  let newRecord = { ...record };
  let name = newRecord.rawFile.name.slice();
  let ext = name.split(".").slice(-1)[0].toUpperCase();
  let imageTypes = ["BMP", "GIF", "JPEG", "JPG", "PNG"];
  if (!imageTypes.find((e) => e === ext)) {
    newRecord.src = require("../../../res/iconos/archivoGenerico.png");
  }
  return (
    <span>
      <ImageField record={newRecord} source="src" title={record.rawFile.name} />{" "}
      <p>{record.rawFile.name}</p>
    </span>
  );
};
