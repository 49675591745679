import React, { Component } from "react";
import { useParams, useNavigate } from "react-router-dom";
import calcularPx from "../calcularPx";
import { ReactComponent as Logo } from "../../../res/iconos/logo.svg";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterAndNavigate;
}

class Cambiar extends Component {
  state = {
    passValida: false,
    passConfirmada: false,
    mensaje: null,
    error: null,
  };

  async cambiarPass() {
    const res = await fetch("/api/cuentas/cambiarPass", {
      method: "post",
      body: JSON.stringify({
        token: this.props.params.token,
        pass: this.state.pass,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();

    if (data.exito) {
      this.setState(
        {
          error: false,
          passConfirmada: false,
          mensaje: "Contraseña cambiada. Redirigiendo a la página principal...",
        },
        () => {
          setTimeout(() => {
            this.props.navigate("/");
          }, 3000);
        }
      );
    } else {
      this.setState(
        {
          error: true,
          passConfirmada: false,
          mensaje:
            "El link expiró. Obtenga uno nuevo en Ingresar --- Olvidé mi contraseña",
        },
        () => {
          setTimeout(() => {
            this.props.navigate("/");
          }, 4000);
        }
      );
    }
  }

  render() {
    const { error, mensaje } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 101,
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: calcularPx(340),
            height: "auto",
            top: calcularPx(100),
            background: "white",
            borderRadius: calcularPx(5),
          }}
        >
          <Logo width={calcularPx(90.17)} height={calcularPx(51)} />

          <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
            <h1 className="tituloAcceso">Contraseña</h1>
            <input
              className="campoAcceso"
              type="password"
              style={{
                marginTop: calcularPx(6),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              onChange={(event) => {
                let pass = event.target.value;
                let valida =
                  0 < pass.length && pass.length < 16 && !pass.includes(" ");
                if (valida) {
                  event.target.style.borderColor = "#3f3f46";
                } else {
                  event.target.style.borderColor = "red";
                }
                document.getElementById("pass2").value = "";
                this.setState({
                  pass: event.target.value,
                  passValida: valida,
                  passConfirmada: false,
                  error: null,
                });
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  document.getElementById("botonCambiar").click();
                }
              }}
            />
          </div>

          <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
            <h1 className="tituloAcceso">Confirmar contraseña</h1>
            <input
              id="pass2"
              className="campoAcceso"
              type="password"
              style={{
                marginTop: calcularPx(6),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              onChange={(event) => {
                let confirmada = event.target.value === this.state.pass;
                if (confirmada) {
                  event.target.style.borderColor = "#3f3f46";
                } else {
                  event.target.style.borderColor = "red";
                }
                this.setState({
                  passConfirmada: event.target.value === this.state.pass,
                  error: null,
                });
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  document.getElementById("botonCambiar").click();
                }
              }}
            />
          </div>

          <button
            id="botonCambiar"
            className="botonAcceso"
            disabled={!this.state.passConfirmada}
            onClick={() => this.cambiarPass()}
            style={{
              marginTop: calcularPx(38),
              width: calcularPx(300),
              height: calcularPx(38),
            }}
          >
            Cambiar contraseña
          </button>
          {mensaje && (
            <div
              className="mensajeAcceso"
              style={{
                marginTop: calcularPx(14),
                background: error ? "red" : "gray",
                width: calcularPx(300),
                height: "auto",
              }}
            >
              {mensaje}
            </div>
          )}
          <div style={{ height: calcularPx(38) }} />
        </div>
      </div>
    );
  }
}

export default withRouterAndNavigate(Cambiar);
