import React, { useEffect, Component } from "react";
import { useParams, useNavigate } from "react-router-dom";
import calcularPx from "../general/calcularPx.js";
import Secciones from "./secciones.jsx";
import Descargas from "./descargas.jsx";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterAndNavigate;
}

class Clientes extends Component {
  state = {
    seccion: this.props.seccion,
    nivelLogin: this.props.nivelLogin,
    mensaje: "Debés estar registrado para ver esta página.",
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.cargo && this.props.nivelLogin < 1) {
      this.cerrarSesion("Debés estar registrado para ver esta página.");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivelLogin: this.props.nivelLogin }, () => {
        if (this.state.nivelLogin == 0) {
          this.cerrarSesion("Debés estar registrado para ver esta página.");
        }
      });
    }
    if (prevProps.seccion != this.props.seccion) {
      this.setState({ seccion: this.props.seccion });
    }
    if (
      prevProps.cargo != this.props.cargo &&
      this.props.cargo &&
      this.props.nivelLogin == 0
    ) {
      this.cerrarSesion("Debés estar registrado para ver esta página.");
    }
  }

  cerrarSesion(mensaje) {
    this.setState({ nivelLogin: 0, mensaje: mensaje }, () => {
      this.props.cookies.remove("token");
      this.props.cerrarSesion();
      setTimeout(() => this.props.navigate("/home"), 3000);
    });
  }

  render() {
    const mobileBanner =
      window.innerWidth < parseInt(calcularPx(650).slice(0, -2));
    return this.state.nivelLogin >= 1 ? (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: calcularPx(203),
            maxHeight: "200px",
            textJustify: "left",
          }}
        >
          <img
            src={require("../../res/banners/clientes.jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100%",
              height: "auto",
              minHeight: calcularPx(203),
              maxHeight: "200px",
              zIndex: -1,
            }}
          />
          <h1
            className={mobileBanner ? "textoBannerMobile" : "textoBanner"}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0%,-50%)",
            }}
          >
            Zona Clientes
          </h1>
        </div>

        <div style={{ height: calcularPx(40) }} />
        {this.state.seccion ? (
          <Descargas cerrarSesion={(mensaje) => this.cerrarSesion(mensaje)} />
        ) : (
          <Secciones cerrarSesion={(mensaje) => this.cerrarSesion(mensaje)} />
        )}
        <div style={{ height: calcularPx(80) }} />
      </React.Fragment>
    ) : (
      <div
        style={{
          marginTop: calcularPx(80),
          textAlign: "center",
        }}
      >
        <h1
          className="tituloError"
          style={{ marginLeft: "10%", marginRight: "10%" }}
        >
          {this.state.mensaje}
        </h1>
        <h1
          className="textoError"
          style={{ marginLeft: "10%", marginRight: "10%" }}
        >
          Redirigiendo a la página principal...
        </h1>
        <div style={{ height: calcularPx(80) }} />
      </div>
    );
  }
}

export default withCookies(withRouterAndNavigate(Clientes));
