import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import { ReactComponent as IcoAcceso } from "../../../res/iconos/acceso.svg";

class AccesoMobile extends Component {
  state = { nivel: this.props.nivelLogin, mostrarSubmenu: false };

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivel: this.props.nivelLogin });
    }
  }

  style1 = {
    position: "relative",
    display: "block",
    width: "100%",
    padding: calcularPx(30) + " " + calcularPx(0),
    textAlign: "left",
    alignItems: "baseline",
    background: "transparent",
    border: "none",
  };

  style2 = {
    position: "relative",
    display: "block",
    color: "#3f3f46",
    padding: calcularPx(30) + " " + calcularPx(15),
    textDecoration: "none",
    fontFamily: "Open Sans",
    fontSize: calcularPx(16),
    fontWeight: 700,
    background: "transparent",
    border: "none",
  };

  render() {
    const { ocultar, botones } = this.props;
    let items;
    switch (this.state.nivel) {
      case 0:
        items = (
          <nav
            className={"submenuProductos"}
            style={{
              position: "relative",
              width: "auto",
              left: calcularPx(30),
              background: "#ffffff",
            }}
          >
            <button
              onClick={() => {
                this.setState({ mostrarSubmenu: false });
                ocultar();
                botones.ingresar();
              }}
              style={this.style2}
            >
              INGRESAR
            </button>
            <button
              onClick={() => {
                this.setState({ mostrarSubmenu: false });
                ocultar();
                botones.registrar();
              }}
              style={this.style2}
            >
              CREAR CUENTA
            </button>
          </nav>
        );
        break;
      default:
        items = (
          <nav
            className={"submenuProductos"}
            style={{
              position: "relative",
              width: "auto",
              left: calcularPx(30),
              background: "#ffffff",
            }}
          >
            <Link
              to={this.state.nivel == 2 ? "/admin" : "/clientes"}
              onClick={() => {
                this.setState({ mostrarSubmenu: false });
                ocultar();
              }}
              style={this.style2}
            >
              {this.state.nivel == 2 ? "ADMINISTRACIÓN" : "ZONA CLIENTES"}
            </Link>
            <button
              onClick={() => {
                this.setState({ mostrarSubmenu: false });
                ocultar();
                botones.cerrarSesion();
              }}
              style={this.style2}
            >
              CERRAR SESIÓN
            </button>
          </nav>
        );
        break;
    }
    return (
      <React.Fragment>
        <div
          style={{
            height: calcularPx(1),
            width: "96.56%",
            backgroundColor: "#C4C4C4",
            marginTop: calcularPx(25),
            marginTop: calcularPx(25),
          }}
        />
        <div>
          <button
            className={"itemNavBar"}
            style={this.style1}
            onClick={() => {
              this.setState({ mostrarSubmenu: !this.state.mostrarSubmenu });
            }}
          >
            <IcoAcceso
              width={calcularPx(25)}
              height={calcularPx(25)}
              style={{
                position: "relative",
                display: "inline",
                marginRight: calcularPx(20),
              }}
            />
            ACCESO
            <IcoChevron
              width={calcularPx(15)}
              height={calcularPx(15)}
              style={{
                position: "absolute",
                left: "90%",
                transform:
                  "scaleY(" + (this.state.mostrarSubmenu ? -1 : 1) + ")",
              }}
            />
          </button>
          <CSSTransition
            in={this.state.mostrarSubmenu}
            timeout={100}
            classNames="opacidad"
            unmountOnExit
          >
            {items}
          </CSSTransition>
        </div>
      </React.Fragment>
    );
  }
}

export default AccesoMobile;
