import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from "react-admin";

import LocalidadSelectInput from "../herramientas/localidadSelectInput";

const filtros = [
  <LocalidadSelectInput source="idLocalidad" label="Localidad" />,
  <SelectInput
    source="tipo"
    choices={[
      { id: "G", name: "Gas" },
      { id: "E", name: "Eléctrico" },
      { id: "A", name: "Ambos" },
    ]}
    validate={[required]}
  />,
];
export const representantesTecnicosList = () => (
  <List filters={filtros} title={"Representantes técnicos"}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="nombre" />
      <TextField source="telefono" label="Teléfono" />
      <TextField source="domicilio" />
      <ReferenceField
        source="idLocalidad"
        reference="localidades"
        label="Localidad"
        link={false}
      >
        <TextField source="nombre" />
      </ReferenceField>
      <ReferenceField
        source="idLocalidad"
        reference="localidades"
        label="Provincia"
        link={false}
      >
        <ReferenceField
          source="idProvincia"
          reference="provincias"
          link={false}
        >
          <TextField source="nombre" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="tipo" label="Tipo" />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <TextInput source="nombre" validate={[required]} />
    <TextInput source="telefono" label="Teléfono" validate={[required]} />
    <LocalidadSelectInput source="idLocalidad" validate={[required]} />
    <TextInput source="domicilio" validate={[required]} />
    <SelectInput
      source="tipo"
      choices={[
        { id: "g", name: "Gas" },
        { id: "e", name: "Eléctrico" },
        { id: "a", name: "Ambos" },
      ]}
      validate={[required]}
    />
  </SimpleForm>
);

export const representantesTecnicosCreate = () => <Create>{form}</Create>;

export const representantesTecnicosEdit = () => <Edit>{form}</Edit>;
