import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../calcularPx.js";

const SubmenuProductos = ({ mobile, ocultarMenu }) => {
  const style = {
    position: "relative",
    display: "block",
    color: mobile ? "#3f3f46" : "#FFFFFF",
    padding: calcularPx(30) + " " + calcularPx(15),
    textDecoration: "none",
    fontFamily: "Open Sans",
    fontSize: calcularPx(16),
    fontWeight: 700,
  };

  return (
    <nav
      style={{
        position: "relative",
        left: mobile ? "" : "-25%",
        top: calcularPx(19.5),
        width: "auto",
        background: mobile ? "#ffffff" : "#3f3f46",
      }}
    >
      <Link
        to="/productos/patagonia"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Convector Patagonia
      </Link>
      <Link
        to="/productos/euro"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Convector Euro
      </Link>
      <Link
        to="/productos/movil"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Convector Móvil
      </Link>
      <Link
        to="/productos/vitroconvector"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Vitroconvector
      </Link>
      <Link
        to="/productos/hornosElectricos"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Hornos eléctricos
      </Link>
      <Link
        to="/productos/termotanquesGas"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Termotanques a gas
      </Link>
      <Link
        to="/productos/termotanquesElectricos"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Termotanques eléctricos
      </Link>
    </nav>
  );
};

export default SubmenuProductos;
