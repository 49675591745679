import React, { Component } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import calcularPx from "./calcularPx.js";
import { CSSTransition } from "react-transition-group";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import Ingreso from "./acceso/ingreso.jsx";
import Registro from "./acceso/registro.jsx";
import NavBar from "./navBar/navBar.jsx";
import Home from "../home/paginaHome.jsx";
import Productos from "../productos/paginaProductos.jsx";
import PuntosDeVenta from "../puntosDeVenta/paginaPuntosDeVenta.jsx";
import ServicioTecnico from "../servicioTecnico/paginaServicioTecnico.jsx";
import Posventa from "../posventa/paginaPosventa.jsx";
import PreguntasFrecuentes from "../preguntasFrecuentes/paginaPreguntasFrecuentes.jsx";
import Calculo from "../calculo/paginaCalculo.jsx";
import Clientes from "../clientes/paginaClientes.jsx";
import Verificar from "./acceso/paginaVerificar.jsx";
import Cambiar from "./acceso/paginaCambiar.jsx";
import NotFound from "./paginaNotFound.jsx";
import Footer from "./footer.jsx";
import RegistrarProducto from "../registrarProducto/paginaRegistrarProducto.jsx";

class Sitio extends Component {
  state = {
    width: 0,
    nivelLogin: 0, //0: no logueado, 1: cliente, 3:admin
    cargo: false,
    mostrarIngresar: false,
    mostrarRegistrar: false,
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.actualizarAncho = this.actualizarAncho.bind(this);
  }

  componentDidMount() {
    fetch("/api/refrescarToken")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let token = this.props.cookies.get("token");
        if (token && token !== undefined && token !== "null") {
          this.setState({ nivelLogin: data.nivelLogin, cargo: true });
        } else {
          this.props.cookies.remove("token");
          this.setState({ nivelLogin: 0, cargo: true });
        }
        this.actualizarAncho();
        window.addEventListener("resize", this.actualizarAncho);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.actualizarAncho);
  }

  actualizarAncho() {
    this.setState({
      width: typeof window !== "undefined" ? window.innerWidth : 0,
    });
  }

  ocultarAcceso() {
    this.setState({ mostrarIngresar: false, mostrarRegistrar: false });
  }

  mostrarIngresar() {
    this.setState({ mostrarRegistrar: false }, () => {
      this.setState({ mostrarIngresar: true });
    });
  }

  mostrarRegistrar() {
    this.setState({ mostrarIngresar: false }, () => {
      this.setState({ mostrarRegistrar: true });
    });
  }

  cerrarSesion() {
    this.props.cookies.remove("token");
    this.setState({ nivelLogin: 0 });
  }

  render() {
    const { mostrarIngresar, mostrarRegistrar } = this.state;
    return (
      <div>
        <div
          style={{
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor:
              mostrarIngresar || mostrarRegistrar
                ? "rgba(0, 0, 0, 0.6)"
                : "transparent",
            zIndex: mostrarIngresar || mostrarRegistrar ? 101 : -1,
          }}
        >
          <CSSTransition
            in={mostrarIngresar}
            timeout={250}
            classNames="submenu"
            unmountOnExit
          >
            <Ingreso
              ocultar={() => this.ocultarAcceso()}
              mostrarRegistrar={() => this.mostrarRegistrar()}
              setLogin={(nivel) => {
                this.setState({ nivelLogin: nivel });
              }}
            />
          </CSSTransition>
          <CSSTransition
            in={mostrarRegistrar}
            timeout={250}
            classNames="submenu"
            unmountOnExit
          >
            <Registro
              ocultar={() => this.ocultarAcceso()}
              mostrarIngresar={() => this.mostrarIngresar()}
            />
          </CSSTransition>
        </div>
        <div
          style={{
            position: "relative",
            minHeight: "calc(100vh - " + calcularPx(303.49) + ")",
          }}
        >
          <NavBar
            nivelLogin={this.state.nivelLogin}
            botones={{
              ingresar: this.mostrarIngresar.bind(this),
              registrar: this.mostrarRegistrar.bind(this),
              cerrarSesion: this.cerrarSesion.bind(this),
            }}
          />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/productos/:linea" element={<Productos />} />
            <Route
              path="/productos/:linea/:modelo/:id/*"
              element={<Productos />}
            />
            <Route path="/puntosDeVenta" element={<PuntosDeVenta />} />
            <Route path="/posventa" element={<Posventa />} />
            <Route
              path="/preguntasFrecuentes"
              element={<PreguntasFrecuentes />}
            />
            <Route
              path="/registrarProducto"
              element={
                <RegistrarProducto
                  nivelLogin={this.state.nivelLogin}
                  cargo={this.state.cargo}
                  cerrarSesion={() => this.cerrarSesion()}
                />
              }
            />
            <Route path="/calculo" element={<Calculo />} />
            <Route
              path="/clientes"
              element={
                <Clientes
                  seccion={false}
                  nivelLogin={this.state.nivelLogin}
                  cargo={this.state.cargo}
                  cerrarSesion={() => this.cerrarSesion()}
                />
              }
            />
            <Route
              path="/clientes/:seccion"
              element={
                <Clientes
                  seccion={true}
                  nivelLogin={this.state.nivelLogin}
                  cargo={this.state.cargo}
                  cerrarSesion={() => this.cerrarSesion()}
                />
              }
            />
            <Route path="/verificar/:token" element={<Verificar />} />
            <Route path="/cambiar/:token" element={<Cambiar />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
        {<Footer />}
      </div>
    );
  }
}

//<Route path="/servicioTecnico" element={<ServicioTecnico />} />

export default withCookies(Sitio);
