import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../general/calcularPx.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BannersDesktop = ({ banners }) => {
  const responsive = {
    todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
  };
  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          maxWidth: "100%",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <Carousel
          responsive={responsive}
          showDots={true}
          draggable={false}
          infinite={true}
          autoPlay={true}
          partialVisible={false}
        >
          {banners.primero
            .filter((e) => e.plataforma == 0 || e.plataforma == 2)
            .map((e, i) => {
              return (
                <Link to={e.link}>
                  <div
                    style={{
                      //maxHeight: "822px",
                    }}
                  >
                    <img
                      alt={"banner1-" + i}
                      src={e.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center top",
                      }}
                    />
                  </div>
                </Link>
              );
            })}
        </Carousel>
      </div>

      <div style={{ height: calcularPx(42), width: "100%" }} />

      {banners.segundo[0] && (
        <Link to={banners.segundo[0].link}>
          <img
            alt="banner2-0"
            src={banners.segundo[0].url}
            style={{
              position: "relative",
              maxWidth: "45.42%",
              left: "3.44%",
              zIndex: 0,
              borderRadius: "0.375rem",
            }}
          />
        </Link>
      )}

      {banners.segundo[1] && (
        <Link to={banners.segundo[1].link}>
          <img
            alt="banner2-1"
            src={banners.segundo[1].url}
            style={{
              position: "relative",
              maxWidth: "45.42%",
              left: "5.72%",
              zIndex: 0,
              borderRadius: "0.375rem",
            }}
          />
        </Link>
      )}

      <div style={{ height: calcularPx(42), width: "100%" }} />

      {banners.tercero[0] && (
        <Link to={banners.tercero[0].link}>
          <img
            alt="banner3-0"
            src={banners.tercero[0].url}
            style={{
              position: "relative",
              maxWidth: "45.42%",
              left: "3.44%",
              zIndex: 0,
              borderRadius: "0.375rem",
            }}
          />
        </Link>
      )}
      {banners.cuarto[0] && (
        <Link to={banners.cuarto[0].link}>
          <img
            alt="banner4-0"
            src={banners.cuarto[0].url}
            style={{
              position: "relative",
              maxWidth: "45.42%",
              left: "5.72%",
              zIndex: 0,
              borderRadius: "0.375rem",
            }}
          />
        </Link>
      )}
    </React.Fragment>
  );
};

export default BannersDesktop;
