import React, { Component } from "react";
import BannersDesktop from "./bannersDesktop.jsx";
import BannersMobile from "./bannersMobile.jsx";

class Home extends Component {
  state = { primero: [], segundo: [], tercero: [], cuarto: [] };

  async getURLImagen(imgPath) {
    const res = await fetch(imgPath);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/home/banners")
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        let i = 0;
        let primero = [];
        let segundo = [];
        let tercero = [];
        let cuarto = [];
        while (i < data.length) {
          let e = data[i];
          let banner = {
            link: e.link,
            plataforma: e.plataforma,
          };
          banner.url = await this.getURLImagen(e.imgPath);
          switch (e.carousel) {
            case 1:
              primero.push(banner);
              break;
            case 2:
              segundo.push(banner);
              this.setState({ primero: primero });
              break;
            case 3:
              tercero.push(banner);
              this.setState({ segundo: segundo });
              break;
            case 4:
              cuarto.push(banner);
              this.setState({ tercero: tercero });
              break;
          }
          i++;
        }
        this.setState({ cuarto: cuarto });
      });
  }

  componentWillUnmount() {
    this.state.primero.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.segundo.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.tercero.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.cuarto.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
  }

  render() {
    const mobile = window.innerWidth < 950;

    return (
      <React.Fragment>
        {mobile ? (
          <BannersMobile banners={this.state} />
        ) : (
          <BannersDesktop banners={this.state} />
        )}
      </React.Fragment>
    );
  }
}

export default Home;
