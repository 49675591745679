import {
  Create,
  Datagrid,
  List,
  NumberField,
  TextField,
  EditButton,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  BooleanInput,
  required,
} from "react-admin";

import { CustomImageField } from "../herramientas/customImageField";

const filtros = [
  <TextInput source="nombre" label="Nombre exacto" />,
  <TextInput source="modelo" label="Modelo exacto" />,
  <BooleanInput source="activo" label="Activo (visible en la página)" />,
];

export const termotanquesList = () => (
  <List filters={filtros} title={"Termotanques"}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="nombre" />
      <TextField source="modelo" />
      <NumberField source="capacidadLt" />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <BooleanInput source="activo" label="Activo (visible en la página)" />
    <TextInput source="nombre" validate={[required]} />
    <TextInput source="modelo" validate={[required]} />
    <TextInput multiline fullWidth source="descripcion" label="Descripción" />
    <ImageInput
      source="imgPath"
      label="Imagen"
      placeholder={
        <p style={{ textAlign: "left" }}>Arrastre una imágen o haga click</p>
      }
      accept="image/*"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <ImageInput
      source="manualPath"
      label="Manual"
      placeholder={
        <p style={{ textAlign: "left" }}>
          Arrastre una imágen o un archivo .pdf, o haga click
        </p>
      }
      accept="image/*,.pdf"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <NumberInput
      source="capacidadLt"
      label="Capacidad en litros"
      validate={[required]}
    />
    <NumberInput source="potencia" label="Potencia" />
    <NumberInput source="altoCm" label="Alto en Cm" validate={[required]} />
    <NumberInput
      source="diametroCm"
      label="Diámetro en Cm"
      validate={[required]}
    />
    <SelectInput
      source="conexionAgua"
      label="Conexión de agua"
      choices={[
        { id: "2", name: "Superior" },
        { id: "1", name: "Superior e inferior" },
        { id: "0", name: "Inferior" },
      ]}
    />
    <SelectInput
      source="recuperacion"
      label="Recuperación"
      choices={[
        { id: "0", name: "Común" },
        { id: "1", name: "Recuperación simultánea" },
        { id: "2", name: "Alta recuperación" },
      ]}
    />
  </SimpleForm>
);

export const termotanquesCreate = () => <Create>{form}</Create>;

export const termotanquesEdit = () => <Edit>{form}</Edit>;
