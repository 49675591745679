import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { ReactComponent as IcoCerrar } from "../../../res/iconos/cerrar.svg";
import { ReactComponent as Logo } from "../../../res/iconos/logo.svg";

class Ingreso extends Component {
  state = {
    mail: "",
    mailValido: false,
    pass: "",
    passValida: false,
    desactivarBoton: true,
    error: false,
    mensaje: null,
  };

  async ingresar() {
    this.setState({ error: false, mensaje: null });
    const res = await fetch("/api/cuentas/ingresar", {
      method: "post",
      body: JSON.stringify({ mail: this.state.mail, pass: this.state.pass }),
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.exito) {
      this.props.setLogin(data.nivel);
      this.props.ocultar();
    } else {
      let mensaje;
      if (data.error === "incorrectos") {
        mensaje = "Email y/o contraseña incorrectos.";
      } else {
        mensaje = "Debe verificar su cuenta. Revise su bandeja de entrada.";
      }
      this.setState({
        error: true,
        mensaje: mensaje,
        desactivarBoton: true,
      });
    }
  }

  async cambiarPass() {
    this.setState({ error: null });
    const res = await fetch("/api/cuentas/olvido", {
      method: "post",
      body: JSON.stringify({ mail: this.state.mail }),
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.exito) {
      this.setState({
        error: false,
        mensaje: "Se envió un mail para cambiar su contraseña.",
        desactivarBoton: true,
      });
    } else {
      this.setState({
        error: true,
        mensaje: "El email no se encuentra registrado.",
        desactivarBoton: true,
      });
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  render() {
    const { error, mensaje } = this.state;
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: calcularPx(340),
          height: "auto",
          top: calcularPx(100),
          background: "white",
          borderRadius: calcularPx(5),
        }}
      >
        <div style={{ position: "absolute" }}>
          <button
            onClick={this.props.ocultar}
            style={{
              position: "relative",
              background: "transparent",
              top: calcularPx(-22),
              left: calcularPx(170),
              border: "none",
            }}
          >
            <IcoCerrar
              width={calcularPx(44)}
              height={calcularPx(44)}
              style={{
                position: "relative",
              }}
            />
          </button>
        </div>

        <Logo width={calcularPx(90.17)} height={calcularPx(51)} />

        <div style={{ marginTop: calcularPx(38), textAlign: "left" }}>
          <h1 className="tituloAcceso">Email</h1>
          <input
            className="campoAcceso"
            type="email"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let valido = this.validarMail(event.target.value);
              if (valido) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              this.setState({
                mail: event.target.value,
                mailValido: valido,
                error: false,
                mensaje: null,
                desactivarBoton: !(valido && this.state.passValida),
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonIngreso").click();
              }
            }}
          />
        </div>
        <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
          <h1 className="tituloAcceso">Contraseña</h1>
          <input
            className="campoAcceso"
            type="password"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let valida = event.target.value.length > 0;
              if (valida) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              this.setState({
                pass: event.target.value,
                passValida: valida,
                error: false,
                mensaje: null,
                desactivarBoton: !(this.state.mailValido && valida),
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonIngreso").click();
              }
            }}
          />
          <p
            className="textoAcceso"
            onClick={() => this.cambiarPass()}
            style={{
              position: "absolute",
              display: this.state.mailValido ? "" : "none",
              cursor: "pointer",
              textAlign: "right",
              right: calcularPx(23),
              marginTop: calcularPx(7),
            }}
          >
            {"Olvidé mi contraseña"}
          </p>
        </div>
        <button
          id="botonIngreso"
          className="botonAcceso"
          disabled={this.state.desactivarBoton}
          onClick={() => this.ingresar()}
          style={{
            marginTop: calcularPx(50),
            width: calcularPx(300),
            height: calcularPx(38),
          }}
        >
          Iniciar Sesión
        </button>
        {mensaje && (
          <div
            className="mensajeAcceso"
            style={{
              marginTop: calcularPx(14),
              background: error ? "red" : "gray",
              width: calcularPx(300),
              height: "auto",
            }}
          >
            {mensaje}
          </div>
        )}
        <p
          className="textoAcceso"
          style={{ textAlign: "center", marginTop: calcularPx(14) }}
        >
          ¿Aún no estás registrado?
          <br />
          Podés registrarte
          <span
            onClick={this.props.mostrarRegistrar}
            style={{ cursor: "pointer", color: "blue" }}
          >
            {" acá"}
          </span>
        </p>
      </div>
    );
  }
}

export default Ingreso;
