import {
  Datagrid,
  ReferenceField,
  List,
  TextField,
  EditButton,
  Edit,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import LocalidadSelectInput from "../herramientas/localidadSelectInput";

const filtros = [
  <LocalidadSelectInput source="idLocalidad" label="Localidad" />,
];

export const puntosDeVentaList = () => (
  <List filters={filtros} title={"Puntos de venta"}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="nombre" />
      <TextField source="telefono" label="Teléfono" />
      <TextField source="domicilio" />
      <ReferenceField
        source="idLocalidad"
        reference="localidades"
        label="Localidad"
        link={false}
      >
        <TextField source="nombre" />
      </ReferenceField>
      <ReferenceField
        source="idLocalidad"
        reference="localidades"
        label="Provincia"
        link={false}
      >
        <ReferenceField
          source="idProvincia"
          reference="provincias"
          link={false}
        >
          <TextField source="nombre" />
        </ReferenceField>
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <TextInput source="nombre" validate={[required]} />
    <TextInput source="telefono" label="Teléfono" validate={[required]} />
    <LocalidadSelectInput source="idLocalidad" validate={[required]} />
    <TextInput source="domicilio" validate={[required]} />
    <NumberInput source="codigoPostal" validate={[required]} />
    <NumberInput source="lng" label="Latitud" />
    <NumberInput source="lat" label="Longitud" />
  </SimpleForm>
);

export const puntosDeVentaCreate = () => <Create>{form}</Create>;

export const puntosDeVentaEdit = () => <Edit>{form}</Edit>;
