import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { ReactComponent as IcoCerrar } from "../../../res/iconos/cerrar.svg";
import { ReactComponent as Logo } from "../../../res/iconos/logo.svg";
import Select from "react-dropdown-select";

class Registro extends Component {
  state = {
    mail: "",
    mailValido: false,
    pass: "",
    passValida: false,
    passConfirmada: false,
    nombre: "",
    nombreValido: false,
    idLocalidad: -1,
    error: null,
    provincias: [],
    localidades: [],
    registroExitoso: false,
  };

  componentDidMount() {
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  async registrar() {
    this.setState({ error: null });
    const res = await fetch("/api/cuentas/registrarComerciante", {
      method: "post",
      body: JSON.stringify({
        mail: this.state.mail,
        pass: this.state.pass,
        nombre: this.state.nombre,
        idLocalidad: this.state.idLocalidad,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.exito) {
      this.setState({ registroExitoso: true });
    } else {
      this.setState({ error: data.error, mailValido: false }); //Se supone que el mail está repetido pero podría ser cualquier cosa
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ localidades: data, idLocalidad: -1 });
        });
    } else {
      this.setState({ localidades: [], idLocalidad: -1 });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      this.setState({ idLocalidad: value.id });
    }
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="selectRegistro"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id={props.placeholder}
            onChange={(event) => {
              methods.setSearch(event);
            }}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const error = this.state.error;
    return this.state.registroExitoso ? (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: calcularPx(340),
          height: "auto",
          top: calcularPx(100),
          background: "white",
          borderRadius: calcularPx(5),
        }}
      >
        <div style={{ position: "absolute" }}>
          <button
            onClick={this.props.ocultar}
            style={{
              position: "relative",
              background: "transparent",
              top: calcularPx(-22),
              left: calcularPx(170),
              border: "none",
            }}
          >
            <IcoCerrar
              width={calcularPx(44)}
              height={calcularPx(44)}
              style={{
                position: "relative",
              }}
            />
          </button>
        </div>

        <Logo width={calcularPx(90.17)} height={calcularPx(51)} />

        <h1
          className="confirmacionRegistro"
          style={{ padding: calcularPx(20), textAlign: "center" }}
        >
          ¡Ya estás registrado!
          <br />
          <br />
          Te enviamos un mail para verificar tu cuenta.
          <br />
          <br />
          Por favor, revisá tu bandeja de entrada.
        </h1>
      </div>
    ) : (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: calcularPx(340),
          height: "auto",
          top: calcularPx(100),
          background: "white",
          borderRadius: calcularPx(5),
        }}
      >
        <div style={{ position: "absolute" }}>
          <button
            onClick={this.props.ocultar}
            style={{
              position: "relative",
              background: "transparent",
              top: calcularPx(-22),
              left: calcularPx(170),
              border: "none",
            }}
          >
            <IcoCerrar
              width={calcularPx(44)}
              height={calcularPx(44)}
              style={{
                position: "relative",
              }}
            />
          </button>
        </div>

        <Logo width={calcularPx(90.17)} height={calcularPx(51)} />

        <div style={{ marginTop: calcularPx(20), textAlign: "left" }}>
          <h1 className="tituloAcceso">Nombre de la empresa</h1>
          <input
            className="campoAcceso"
            type="text"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let valido = event.target.value.length > 0;
              if (valido) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              this.setState({
                nombre: event.target.value,
                nombreValido: valido,
                error: null,
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonRegistro").click();
              }
            }}
          />
        </div>

        <div style={{ marginTop: calcularPx(20), textAlign: "left" }}>
          <h1 className="tituloAcceso">Provincia</h1>
          <Select
            className="dropRegistro"
            style={{
              border: calcularPx(1) + " solid #3f3f46",
              borderRadius: calcularPx(5),
              width: calcularPx(300),
              height: calcularPx(43),
              left: calcularPx(-3),
            }}
            color="#EF7B10"
            placeholder="Provincia"
            searchable={true}
            separator={true}
            noDataLabel={"No hay resultados."}
            handleKeyDownFn={({ methods, state }) => {
              let input = document.getElementById("Provincia");
              input.focus();
              if (state.values[0]) {
                methods.clearAll();
                input.value = "";
              }
            }}
            separatorRenderer={({ methods, state }) => {
              this.selectProvMethods = methods;
            }}
            dropdownGap={0}
            labelField={"nombre"}
            searchBy={"nombre"}
            valueField={"id"}
            options={this.state.provincias}
            onChange={(values) => this.handleChangeProvincia(values[0])}
            contentRenderer={this.customContentRenderer}
            searchFn={this.customSearch}
          />
        </div>

        <div style={{ marginTop: calcularPx(20), textAlign: "left" }}>
          <h1 className="tituloAcceso">Localidad</h1>
          <Select
            className="dropRegistro"
            style={{
              border: calcularPx(1) + " solid #3f3f46",
              borderRadius: calcularPx(5),
              width: calcularPx(300),
              height: calcularPx(43),
              left: calcularPx(-3),
            }}
            color="#EF7B10"
            placeholder="Localidad"
            searchable={true}
            separator={true}
            noDataLabel={"No hay resultados."}
            handleKeyDownFn={({ methods, state }) => {
              let input = document.getElementById("Localidad");
              input.focus();
              if (state.values[0]) {
                methods.clearAll();
                input.value = "";
              }
            }}
            separatorRenderer={({ methods }) => {
              this.selectLocMethods = methods;
            }}
            dropdownGap={0}
            labelField={"nombre"}
            searchBy={"nombre"}
            valueField={"id"}
            options={this.state.localidades}
            onChange={(values) => this.handleChangeLocalidad(values[0])}
            contentRenderer={this.customContentRenderer}
            searchFn={this.customSearch}
          />
        </div>

        <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
          <h1 className="tituloAcceso">Email</h1>
          <input
            className="campoAcceso"
            type="email"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let valido = this.validarMail(event.target.value);
              if (valido) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              this.setState({
                mail: event.target.value,
                mailValido: valido,
                error: null,
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonRegistro").click();
              }
            }}
          />
        </div>

        <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
          <h1 className="tituloAcceso">Contraseña</h1>
          <input
            className="campoAcceso"
            type="password"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let pass = event.target.value;
              let valida =
                0 < pass.length && pass.length < 16 && !pass.includes(" ");
              if (valida) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              document.getElementById("pass2").value = "";
              this.setState({
                pass: event.target.value,
                passValida: valida,
                passConfirmada: false,
                error: null,
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonRegistro").click();
              }
            }}
          />
        </div>
        <div style={{ marginTop: calcularPx(16), textAlign: "left" }}>
          <h1 className="tituloAcceso">Confirmar contraseña</h1>
          <input
            id="pass2"
            className="campoAcceso"
            type="password"
            style={{
              marginTop: calcularPx(6),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            onChange={(event) => {
              let confirmada = event.target.value === this.state.pass;
              if (confirmada) {
                event.target.style.borderColor = "#3f3f46";
              } else {
                event.target.style.borderColor = "red";
              }
              this.setState({
                passConfirmada: event.target.value === this.state.pass,
                error: null,
              });
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                document.getElementById("botonRegistro").click();
              }
            }}
          />
        </div>

        <h1
          className="textoAcceso"
          style={{ marginTop: calcularPx(16), fontSize: calcularPx(15) }}
        >
          Todos los campos son obligatorios.
        </h1>

        <button
          id="botonRegistro"
          className="botonAcceso"
          disabled={
            !(
              this.state.mailValido &&
              this.state.passConfirmada &&
              this.state.nombreValido &&
              this.state.idLocalidad != -1
            )
          }
          onClick={() => this.registrar()}
          style={{
            marginTop: calcularPx(10),
            width: calcularPx(300),
            height: calcularPx(38),
          }}
        >
          Registrar
        </button>

        {error && (
          <div
            className="mensajeAcceso"
            style={{
              marginTop: calcularPx(14),
              background: "red",
              width: calcularPx(300),
              height: "auto",
            }}
          >
            Ya existe un usuario con ese Email.
          </div>
        )}

        <p
          className="textoAcceso"
          style={{ textAlign: "center", marginTop: calcularPx(14) }}
        >
          ¿Ya estás registrado?
          <br />
          Podés ingresar
          <span
            onClick={this.props.mostrarIngresar}
            style={{ cursor: "pointer", color: "blue" }}
          >
            {" acá"}
          </span>
        </p>
      </div>
    );
  }
}

export default Registro;
