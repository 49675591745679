import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../general/calcularPx.js";

class Secciones extends Component {
  state = { secciones: [] };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/clientes/portadas/")
      .then((res) => res.json())
      .then(async (data) => {
        if (data.error) {
          this.props.cerrarSesion(
            "Tu sesión expiró. Por favor, ingresá de nuevo."
          );
        } else {
          let i = 0;
          let secciones = [];
          let res;
          let blob;
          let url;
          while (i < data.length) {
            res = await fetch(data[i].filePath);
            blob = await res.blob();
            url = URL.createObjectURL(blob);
            secciones.push({ titulo: data[i].seccion, imgUrl: url });
            i++;
          }
          this.setState({ secciones: secciones });
        }
      });
  }

  render() {
    const a = parseInt(
      (window.innerWidth - calcularPx(20.62).slice(0, -2)) /
        parseInt(calcularPx(197.62).slice(0, -2))
    );
    const b = this.state.secciones.length;
    const cantColumnas = (a + b - Math.abs(a - b)) / 2;
    return (
      <React.Fragment>
        <div
          className="tituloClientes"
          style={{
            marginTop: calcularPx(44),
            marginBottom: calcularPx(91.23),
            marginLeft: "3.44%",
            marginRight: "3.44%",
            textAlign: "center",
          }}
        >
          Descargá el material publicitario que necesites para tu negocio.
        </div>
        <div
          style={{
            position: "relative",
            display: "grid",
            flexShrink: 0,
            gridAutoColumns: calcularPx(177),
            gridTemplateColumns: "auto ".repeat(cantColumnas),
            gridAutoRows: calcularPx(200),
            placeItems: "center",
            placeContent: "center",
            width: "100%",
            gridGap: calcularPx(20.62),
          }}
        >
          {this.state.secciones.map((s) => (
            <Link to={"/clientes/" + s.titulo}>
              <div style={{ height: calcularPx(177), width: calcularPx(177) }}>
                <img
                  src={s.imgUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h1
                className="nombreArchivo"
                style={{ marginTop: calcularPx(8), textAlign: "center" }}
              >
                {s.titulo}
              </h1>
            </Link>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Secciones;
