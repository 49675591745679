import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import Select from "react-dropdown-select";

class Posventa extends Component {
  state = {
    email: "",
    emailValido: false,
    nombre: "",
    nombreValido: false,
    apellido: "",
    apellidoValido: false,
    dni: "",
    dniValido: false,
    celular: "",
    celularValido: false,
    calle: "",
    calleValido: false,
    altura: "",
    alturaValido: false,
    piso: "",
    pisoValido: true,
    dpto: "",
    dptoValido: true,
    idProvincia: -1,
    provincias: [],
    provincia: "",
    provinciaValido: false,
    localidades: [],
    idLocalidad: -1,
    localidad: "",
    localidadValido: false,
    codpostal: "",
    codpostalValido: false,
    nombreInstalador: "",
    nombreInstaladorValido: false,
    apellidoInstalador: "",
    apellidoInstaladorValido: false,
    matriculaInstalador: "",
    matriculaInstaladorValido: false,
    producto: "",
    productoValido: false,
    serie: "",
    serieValido: false,
    fecha_compra: "",
    fecha_compraValido: false,
    comercio: "",
    comercioValido: false,
    archivoFactura: null,
    nombreFactura: "Elegir archivo",
    archivoImagen1: null,
    nombreImagen1: "Elegir imagen",
    archivoImagen2: null,
    nombreImagen2: "Elegir imagen",
    falla: "",
    fallaValido: false,
    error: null,
    enviando: false,
    nroReclamo: -1,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  fileToDataUrl = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  fileToBase64 = (blob) => this.fileToDataUrl(blob).then((text) => text);

  async enviar() {
    this.setState({ error: null, enviando: true });
    let archivoFactura = await this.fileToBase64(this.state.archivoFactura);
    let archivoImagen1 = await this.fileToBase64(this.state.archivoImagen1);
    let archivoImagen2 = await this.fileToBase64(this.state.archivoImagen2);
    const res = await fetch("/api/posventa/cargarReclamo", {
      method: "post",
      body: JSON.stringify({
        email: this.state.email,
        nombre: this.state.nombre,
        apellido: this.state.apellido,
        dni: this.state.dni,
        celular: this.state.celular,
        calle: this.state.calle,
        altura: this.state.altura,
        piso: this.state.piso,
        dpto: this.state.dpto,
        idProvincia: this.state.idProvincia,
        provincia: this.state.provincia,
        idLocalidad: this.state.idLocalidad,
        localidad: this.state.localidad,
        codpostal: this.state.codpostal,
        nombreInstalador: this.state.nombreInstalador,
        apellidoInstalador: this.state.apellidoInstalador,
        matriculaInstalador: this.state.matriculaInstalador,
        producto: this.state.producto,
        serie: this.state.serie,
        fecha_compra: this.state.fecha_compra,
        comercio: this.state.comercio,
        archivoFactura: archivoFactura,
        facturaOriginal: this.state.nombreFactura,
        archivoImagen1: archivoImagen1,
        imagen1Original: this.state.nombreImagen1,
        archivoImagen2: archivoImagen2,
        imagen2Original:
          archivoImagen2 != null ? this.state.nombreImagen2 : "null",
        falla: this.state.falla,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.nroReclamo !== -1) {
      this.setState({ nroReclamo: data.nroReclamo, enviando: false });
    } else {
      this.setState({ enviando: false });
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRendererGenero({ props }) {
    return (
      <React.Fragment>
        <div
          className="selectCalculo"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id="selectGenero"
            readOnly={true}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            idProvincia: value.id,
            provincia: value.nombre,
            provinciaValido: true,
            idLocalidad: -1,
            localidad: "",
            localidadValido: false,
            localidades: data,
          });
        });
    } else {
      this.setState({
        idProvincia: -1,
        provincia: "",
        provinciaValido: false,
        idLocalidad: -1,
        localidad: "",
        localidadValido: false,
        localidades: [],
      });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      this.setState({
        idLocalidad: value.id,
        localidad: value.nombre,
        localidadValido: true,
      });
    }
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="selectRT"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id={props.placeholder}
            readOnly={!props.searchable}
            onChange={(event) => methods.setSearch(event)}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobileBanner =
      window.innerWidth < parseInt(calcularPx(650).slice(0, -2));
    const mobile =
      window.innerWidth * 0.8 < parseInt(calcularPx(1163).slice(0, -2));
    const cantColumnas = mobile
      ? (window.innerWidth * 0.8 - parseInt(calcularPx(150).slice(0, -2))) /
        parseInt(calcularPx(300).slice(0, -2))
      : 3;
    const styleCampo = {
      marginTop: calcularPx(3),
      width: calcularPx(300),
      height: calcularPx(43),
      border: calcularPx(1) + " solid #d1d1d1",
      borderRadius: calcularPx(5),
      background: "transparent",
      color: "#3f3f46",
    };
    let f = new Date();
    const offset = f.getTimezoneOffset();
    f = new Date(f.getTime() - offset * 60 * 1000);
    let fecha = f.toISOString().split("T")[0];
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: calcularPx(203),
            maxHeight: "200px",
            textJustify: "left",
          }}
        >
          <img
            src={require("../../res/banners/posventa.png")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100%",
              height: "auto",
              minHeight: calcularPx(203),
              maxHeight: "200px",
              zIndex: -1,
            }}
          />
          <h1
            className={mobileBanner ? "textoBannerMobile" : "textoBanner"}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0%,-50%)",
            }}
          >
            Servicio Posventa
          </h1>
        </div>

        <div style={{ width: "100%", height: calcularPx(48) }} />

        {this.state.nroReclamo == -1 ? (
          <React.Fragment>
            <h1
              className="tituloPosventa"
              style={{
                textAlign: "justify",
                marginLeft: mobile ? "10%" : "auto",
                marginRight: mobile ? "10%" : "auto",
                width: "80%",
                maxWidth: calcularPx(1063),
              }}
            >
              Si necesitás un Servicio Posventa, por favor completá el
              formulario y en breve te contactamos. ¡Muchas Gracias!
            </h1>

            <div style={{ width: "100%", height: calcularPx(32) }} />

            <div
              style={{
                width: "80%",
                maxWidth: calcularPx(1063),
                height: "auto",
                margin: "auto",
                paddingTop: calcularPx(32),
                paddingBottom: calcularPx(32),
                paddingLeft: calcularPx(50),
                paddingRight: calcularPx(50),
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: calcularPx(5),
              }}
            >
              <h1
                className="campoPosventa"
                style={{ fontSize: calcularPx(16) }}
              >
                Los campos marcados con{" "}
                <span style={{ color: "#ff0000" }}>*</span> son obligatorios.
              </h1>
              <div>
                <h1 className="subtituloPosventa" style={{ display: "inline" }}>
                  Datos personales
                </h1>
                <div
                  style={{
                    display: "grid",
                    gridAutoColumns: calcularPx(300),
                    gridTemplateColumns: "auto ".repeat(cantColumnas),
                    gridAutoRows: calcularPx(85),
                    placeItems: "center",
                    gridGap: calcularPx(18),
                  }}
                >
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Nombre<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          nombre: event.target.value,
                          nombreValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Apellido<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          apellido: event.target.value,
                          apellidoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      DNI (sin puntos)
                      <span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      pattern="[0-9]*"
                      style={styleCampo}
                      value={this.state.dni}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 6 < value.length && value.length <= 9;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          dni: value,
                          dniValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Email<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="email"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = this.validarMail(event.target.value);
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          email: event.target.value,
                          emailValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Celular<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="tel"
                      style={styleCampo}
                      value={this.state.celular}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9\+\-\s]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 6 < value.length && value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          celular: value,
                          celularValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div style={{ height: calcularPx(36) }} />
                <h1 className="subtituloPosventa" style={{ display: "inline" }}>
                  Domicilio
                </h1>
                <div
                  style={{
                    display: "grid",
                    gridAutoColumns: calcularPx(300),
                    gridTemplateColumns: "auto ".repeat(cantColumnas),
                    gridAutoRows: calcularPx(85),
                    placeItems: "center",
                    gridGap: calcularPx(18),
                  }}
                >
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Calle<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 25;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          calle: event.target.value,
                          calleValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Altura<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      pattern="[0-9]*"
                      style={styleCampo}
                      value={this.state.altura}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 0 < value.length && value.length <= 6;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          altura: value,
                          alturaValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">Piso</h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          piso: event.target.value,
                          pisoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">Departamento</h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          dpto: event.target.value,
                          dptoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Provincia<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <Select
                      className="dropRT"
                      style={{
                        border: calcularPx(1) + " solid #D1D1D1",
                        borderRadius: calcularPx(5),
                        width: calcularPx(300),
                        height: calcularPx(43),
                      }}
                      color="#EF7B10"
                      placeholder="Provincia"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Provincia");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectProvMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.provincias}
                      onChange={(values) =>
                        this.handleChangeProvincia(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Localidad<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <Select
                      className="dropRT"
                      style={{
                        border: calcularPx(1) + " solid #D1D1D1",
                        borderRadius: calcularPx(5),
                        width: calcularPx(300),
                        height: calcularPx(43),
                      }}
                      color="#EF7B10"
                      placeholder="Localidad"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Localidad");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectLocMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.localidades}
                      onChange={(values) =>
                        this.handleChangeLocalidad(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Código postal<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 10;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          codpostal: event.target.value,
                          codpostalValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div style={{ height: calcularPx(36) }} />
                <h1 className="subtituloPosventa" style={{ display: "inline" }}>
                  Datos del producto
                </h1>
                <div
                  style={{
                    display: "grid",
                    gridAutoColumns: calcularPx(300),
                    gridTemplateColumns: "auto ".repeat(cantColumnas),
                    gridAutoRows: calcularPx(85),
                    placeItems: "center",
                    gridGap: calcularPx(18),
                  }}
                >
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Producto<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          producto: event.target.value,
                          productoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Nº de serie<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          serie: event.target.value,
                          serieValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Fecha de compra<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      type="date"
                      className="campoPosventa"
                      style={styleCampo}
                      max={fecha}
                      onChange={(event) => {
                        let fn = new Date(event.target.value);
                        let valido = fn <= f;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          fecha_compra: event.target.value,
                          fecha_compraValido: valido,
                          error: null,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Comercio<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          comercio: event.target.value,
                          comercioValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: calcularPx(18) }} />
                <div
                  style={{
                    display: "grid",
                    gridAutoColumns: calcularPx(300),
                    gridTemplateColumns: "auto ".repeat(cantColumnas),
                    gridAutoRows: calcularPx(85),
                    placeItems: "center",
                    gridGap: calcularPx(18),
                  }}
                >
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Factura de compra
                      <span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <button
                      onClick={() =>
                        document.getElementById("archivoFactura").click()
                      }
                      classname="campoPosventa"
                      style={{
                        marginTop: calcularPx(3),
                        width: calcularPx(300),
                        height: calcularPx(43),
                        border: calcularPx(1) + " solid #d1d1d1",
                        borderRadius: calcularPx(5),
                        background: "transparent",
                        color: "#3f3f46",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {this.state.nombreFactura}
                    </button>
                    <input
                      id="archivoFactura"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: calcularPx(1),
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file.size <= 15728640) {
                          let tipos = [
                            "PDF",
                            "BMP",
                            "GIF",
                            "JPEG",
                            "JPG",
                            "PNG",
                          ];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoFactura: file,
                              nombreFactura: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son pdf, bmp, gif, jpeg, jpg y png."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoFactura: null,
                              nombreFactura: "Elegir archivo",
                            });
                          }
                        } else {
                          alert("Cada archivo debe pesar menos de 15MB.");
                          event.target.files = [];
                          this.setState({
                            archivoFactura: null,
                            nombreFactura: "Elegir archivo",
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Imagen del producto completo
                      <span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <button
                      onClick={() =>
                        document.getElementById("archivoImagen1").click()
                      }
                      classname="campoPosventa"
                      style={{
                        marginTop: calcularPx(3),
                        width: calcularPx(300),
                        height: calcularPx(43),
                        border: calcularPx(1) + " solid #d1d1d1",
                        borderRadius: calcularPx(5),
                        background: "transparent",
                        color: "#3f3f46",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {this.state.nombreImagen1}
                    </button>
                    <input
                      id="archivoImagen1"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: calcularPx(1),
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file.size <= 15728640) {
                          let tipos = ["BMP", "GIF", "JPEG", "JPG", "PNG"];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoImagen1: file,
                              nombreImagen1: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son bmp, gif, jpeg, jpg y png."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoImagen1: null,
                              nombreImagen1: "Elegir imagen",
                            });
                          }
                        } else {
                          alert("Cada archivo debe pesar menos de 15MB.");
                          event.target.files = [];
                          this.setState({
                            archivoImagen1: null,
                            nombreImagen1: "Elegir imagen",
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Imagen del Tiraje o Salida
                      <span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <button
                      onClick={() =>
                        document.getElementById("archivoImagen2").click()
                      }
                      classname="campoPosventa"
                      style={{
                        marginTop: calcularPx(3),
                        width: calcularPx(300),
                        height: calcularPx(43),
                        border: calcularPx(1) + " solid #d1d1d1",
                        borderRadius: calcularPx(5),
                        background: "transparent",
                        color: "#3f3f46",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {this.state.nombreImagen2}
                    </button>
                    <input
                      id="archivoImagen2"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: calcularPx(1),
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file.size <= 15728640) {
                          let tipos = ["BMP", "GIF", "JPEG", "JPG", "PNG"];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoImagen2: file,
                              nombreImagen2: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son bmp, gif, jpeg, jpg y png."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoImagen2: null,
                              nombreImagen2: "Elegir imagen",
                            });
                          }
                        } else {
                          alert("Cada archivo debe pesar menos de 15MB.");
                          event.target.files = [];
                          this.setState({
                            archivoImagen2: null,
                            nombreImagen2: "Elegir imagen",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: calcularPx(24) }} />
                <h1
                  className="campoPosventa"
                  style={{ fontSize: calcularPx(16) }}
                >
                  *Cuando tome una foto del producto, ubique la cámara frente a
                  él abarcando la totalidad del mismo.
                </h1>
                <h1
                  className="campoPosventa"
                  style={{ fontSize: calcularPx(16) }}
                >
                  *Cuando tome una foto de la salida o tiraje del producto,
                  recuerde que en el caso de los convectores se encuentra en la
                  parte posterior, y con respecto a los termotanques en la parte
                  superior o inferior dependiendo del modelo.
                </h1>
              </div>

              <div style={{ height: calcularPx(24) }} />
              <h1 className="varPosventa">
                Falla<span style={{ color: "#ff0000" }}>*</span>
              </h1>
              <textarea
                className="campoPosventa"
                type="text"
                style={{
                  marginTop: calcularPx(3),
                  width: "100%",
                  height: calcularPx(186),
                  border: calcularPx(1) + " solid #d1d1d1",
                  borderRadius: calcularPx(5),
                  background: "transparent",
                  color: "#3f3f46",
                }}
                onChange={(event) => {
                  let valido = event.target.value.length > 0;
                  if (valido) {
                    event.target.style.borderColor = "#d1d1d1";
                  } else {
                    event.target.style.borderColor = "red";
                  }
                  this.setState({
                    falla: event.target.value,
                    fallaValido: valido,
                    error: null,
                  });
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    document.getElementById("botonEnviar").click();
                  }
                }}
              />

              <div>
                <div style={{ height: calcularPx(36) }} />
                <h1 className="subtituloPosventa" style={{ display: "inline" }}>
                  {"Datos del instalador"}
                </h1>
                <div
                  style={{
                    display: "grid",
                    gridAutoColumns: calcularPx(300),
                    gridTemplateColumns: "auto ".repeat(cantColumnas),
                    gridAutoRows: calcularPx(85),
                    placeItems: "center",
                    gridGap: calcularPx(18),
                  }}
                >
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Nombre<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          nombreInstalador: event.target.value,
                          nombreInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Apellido<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          apellidoInstalador: event.target.value,
                          apellidoInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ height: calcularPx(18) }} />
                    <h1 className="varPosventa">
                      Matrícula<span style={{ color: "#ff0000" }}>*</span>
                    </h1>
                    <input
                      className="campoPosventa"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length > 0;
                        if (valido) {
                          event.target.style.borderColor = "#d1d1d1";
                        } else {
                          event.target.style.borderColor = "red";
                        }
                        this.setState({
                          matriculaInstalador: event.target.value,
                          matriculaInstaladorValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: calcularPx(24) }} />
                <h1
                  className="campoPosventa"
                  style={{ fontSize: calcularPx(16) }}
                >
                  *En el caso de ser un producto eléctrico, completar el campo{" "}
                  <b>“matrícula”</b> con la palabra <b>“eléctrico”</b>
                </h1>
              </div>

              <div style={{ height: calcularPx(32) }} />

              <button
                id="botonEnviar"
                className="botonAcceso"
                disabled={
                  !(
                    this.state.alturaValido &&
                    this.state.apellidoValido &&
                    this.state.apellidoInstaladorValido &&
                    this.state.archivoFactura != null &&
                    this.state.archivoImagen1 != null &&
                    this.state.archivoImagen2 != null &&
                    this.state.calleValido &&
                    this.state.celularValido &&
                    this.state.comercioValido &&
                    this.state.codpostalValido &&
                    this.state.dptoValido &&
                    this.state.dniValido &&
                    this.state.fallaValido &&
                    this.state.fecha_compraValido &&
                    this.state.productoValido &&
                    this.state.localidadValido &&
                    this.state.emailValido &&
                    this.state.matriculaInstaladorValido &&
                    this.state.nombreValido &&
                    this.state.nombreInstaladorValido &&
                    this.state.serieValido &&
                    this.state.pisoValido &&
                    this.state.provinciaValido &&
                    this.state.error == null &&
                    !this.state.enviando
                  )
                }
                onClick={() => this.enviar()}
                style={{
                  width: calcularPx(219),
                  height: calcularPx(38),
                  marginLeft: "auto",
                  marginRight: mobile ? "auto" : "0%",
                }}
              >
                Enviar
              </button>
              {this.state.error != null && (
                <React.Fragment>
                  <div style={{ height: calcularPx(18) }} />{" "}
                  <div
                    className="mensajeAcceso"
                    style={{
                      marginTop: calcularPx(14),
                      background: "red",
                      width: calcularPx(300),
                      height: "auto",
                      margin: "auto",
                    }}
                  >
                    Hubo un error al enviar su reclamo. Reintente más tarde.
                  </div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        ) : (
          <h1
            className="tituloPosventa"
            style={{
              textAlign: "center",
              marginLeft: mobile ? "10%" : "auto",
              marginRight: mobile ? "10%" : "auto",
              width: "80%",
              maxWidth: calcularPx(1063),
            }}
          >
            ¡Muchas gracias! Tu número de reclamo es {this.state.nroReclamo}.{" "}
            <br /> Pronto estaremos en contacto.
          </h1>
        )}

        <div style={{ width: "100%", height: calcularPx(32) }} />
      </React.Fragment>
    );
  }
}

export default Posventa;
