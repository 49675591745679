import { useController } from "react-hook-form";
import React, { Component } from "react";
import calcularPx from "../../general/calcularPx";
import Select from "react-dropdown-select";

function withController(Component) {
  function ComponentWithController(props) {
    let controller = useController({
      name: "idLocalidad",
      rules: { required: true },
      defaultValue: 0,
    });
    return <Component {...props} controller={controller} />;
  }
  return ComponentWithController;
}

class LocalidadSelectInput extends Component {
  state = { provincias: [], localidades: [], seleccionado: 0 };

  constructor(props) {
    super(props);
    this.input = this.props.controller;
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="selectPV"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id={props.placeholder}
            onChange={(event) => methods.setSearch(event)}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ localidades: data });
        });
    } else {
      this.setState({ localidades: [] });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      if (value.id !== this.state.seleccionado) {
        this.input.field.onChange(value.id);
        this.input.field.value = value.id;

        this.setState({
          seleccionado: value.id,
        });
      }
    }
  }

  render() {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: calcularPx(20),
        }}
      >
        <Select
          className="dropPV"
          style={{
            position: "relative",
            border: calcularPx(1) + " solid #D1D1D1",
            borderRadius: calcularPx(5),
            marginRight: calcularPx(30),
            width: calcularPx(300),
            height: calcularPx(43),
          }}
          color="#EF7B10"
          placeholder="Provincia"
          searchable={true}
          separator={true}
          noDataLabel={"No hay resultados."}
          handleKeyDownFn={({ methods, state }) => {
            let input = document.getElementById("Provincia");
            input.focus();
            if (state.values[0]) {
              methods.clearAll();
              input.value = "";
            }
          }}
          separatorRenderer={({ methods, state }) => {
            this.selectProvMethods = methods;
          }}
          dropdownGap={0}
          labelField={"nombre"}
          searchBy={"nombre"}
          valueField={"id"}
          options={this.state.provincias}
          onChange={(values) => this.handleChangeProvincia(values[0])}
          contentRenderer={this.customContentRenderer}
          searchFn={this.customSearch}
        />
        <Select
          className="dropPV"
          style={{
            position: "relative",
            border: calcularPx(1) + " solid #D1D1D1",
            borderRadius: calcularPx(5),
            width: calcularPx(300),
            height: calcularPx(43),
          }}
          color="#EF7B10"
          placeholder="Localidad"
          searchable={true}
          separator={true}
          noDataLabel={"No hay resultados."}
          handleKeyDownFn={({ methods, state }) => {
            let input = document.getElementById("Localidad");
            input.focus();
            if (state.values[0]) {
              methods.clearAll();
              input.value = "";
            }
          }}
          separatorRenderer={({ methods }) => {
            this.selectLocMethods = methods;
          }}
          dropdownGap={0}
          labelField={"nombre"}
          searchBy={"nombre"}
          valueField={"id"}
          options={this.state.localidades}
          onChange={(values) => this.handleChangeLocalidad(values[0])}
          contentRenderer={this.customContentRenderer}
          searchFn={this.customSearch}
        />
      </span>
    );
  }
}

export default withController(LocalidadSelectInput);
