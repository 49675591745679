import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { Link } from "react-router-dom";
import { ReactComponent as IcoLogout } from "../../../res/iconos/logout.svg";

class AccesoDesktop extends Component {
  state = { nivel: this.props.nivelLogin };

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivel: this.props.nivelLogin });
    }
  }

  render() {
    let retorno;
    switch (this.state.nivel) {
      case 0:
        retorno = (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              zIndex: 100,
              top: calcularPx(31),
              right: "3.44%",
            }}
          >
            <button
              onClick={() => {
                this.props.ocultar();
                this.props.botones.ingresar();
              }}
              className={"itemNavBar"}
              style={{
                border: "none",
                background: "transparent",
                flexShrink: 0,
                paddingRight: calcularPx(30),
              }}
            >
              Ingresar
            </button>

            <button
              onClick={() => {
                this.props.ocultar();
                this.props.botones.registrar();
              }}
              className={"itemNavBar"}
              style={{
                top: calcularPx(-8),
                height: calcularPx(40),
                width: calcularPx(136),
                borderRadius: calcularPx(4),
                border: "1px solid #52525B",
                backgroundColor: "Transparent",
                flexShrink: 0,
              }}
            >
              Crear cuenta
            </button>
          </div>
        );
        break;

      default:
        retorno = (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "baseline",
              zIndex: 100,
              top: calcularPx(34),
              right: "2.5%",
            }}
          >
            <Link
              to={this.state.nivel == 2 ? "/admin" : "/clientes"}
              className="itemNavBar"
              onClick={() => {
                this.props.ocultar();
              }}
            >
              {this.state.nivel == 2 ? "ADMINISTRACIÓN" : "ZONA CLIENTES"}
            </Link>
            <button
              className="itemNavBar"
              style={{
                border: "none",
                background: "transparent",
                marginLeft: calcularPx(20),
              }}
              onClick={() => {
                this.props.ocultar();
                this.props.botones.cerrarSesion();
              }}
            >
              Salir
              <IcoLogout
                style={{ marginLeft: calcularPx(5) }}
                width={30}
                height={30}
              />
            </button>
          </div>
        );
        break;
    }
    return retorno;
  }
}

export default AccesoDesktop;
