import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Mapa from "../general/mapa/mapa.jsx";
import Select from "react-dropdown-select";

class PuntosDeVenta extends Component {
  state = {
    provincias: [],
    localidades: [],
    puntos: [],
    seleccionado: { id: -1, lat: -34.613292, lng: -58.388752 },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let seleccionado = { id: -1, lat: -34.613292, lng: -58.388752 };
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          seleccionado = {
            id: -1,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setState(
            {
              seleccionado: seleccionado,
            },
            () => {
              this.setPuntos(seleccionado);
            }
          );
        },
        (error) => {
          this.setPuntos(seleccionado);
        }
      );
    } else {
      this.setPuntos(seleccionado);
    }
    fetch("/api/mapa/provincias")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ provincias: data });
      });
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ localidades: data });
        });
    } else {
      this.setState({ localidades: [] });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      if (value.id !== this.state.seleccionado.id) {
        let seleccionado = {
          id: value.id,
          lat: Number(value.centerLat),
          lng: Number(value.centerLng),
        };
        this.setState({
          seleccionado: seleccionado,
        });
        this.setPuntos(seleccionado);
      }
    }
  }

  setPuntos(seleccionado) {
    fetch(
      "/api/mapa/puntosDeVenta/?idLoc=" +
        seleccionado.id +
        "&lat=" +
        seleccionado.lat +
        "&lng=" +
        seleccionado.lng
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ puntos: data });
      });
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="selectPV"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id={props.placeholder}
            onChange={(event) => methods.setSearch(event)}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobile = window.innerWidth < parseInt(calcularPx(1510).slice(0, -2));
    const mobileBanner =
      window.innerWidth < parseInt(calcularPx(650).slice(0, -2));
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: calcularPx(203),
            maxHeight: "200px",
            textJustify: "left",
          }}
        >
          <img
            src={require("../../res/banners/puntosDeVenta.jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100%",
              height: "auto",
              minHeight: calcularPx(203),
              maxHeight: "200px",
              zIndex: -1,
            }}
          />
          <h1
            className={mobileBanner ? "textoBannerMobile" : "textoBanner"}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0%,-50%)",
            }}
          >
            Puntos de venta
          </h1>
        </div>

        <div style={{ height: calcularPx(30) }} />

        <div style={{ display: mobile ? "block" : "flex" }}>
          <div
            style={{
              position: "relative",
              border: calcularPx(1) + " solid #D1D1D1",
              borderRadius: calcularPx(5),
              width: "80vw",
              maxWidth: calcularPx(340),
              height: calcularPx(193),
              marginLeft: "auto",
              marginRight: mobile ? "auto" : "0",
            }}
          >
            <h1
              className="tituloPV"
              style={{
                position: "absolute",
                top: calcularPx(23),
                left: calcularPx(20),
              }}
            >
              Buscá puntos de venta cercanos
            </h1>
            <Select
              className="dropPV"
              style={{
                position: "absolute",
                border: calcularPx(1) + " solid #D1D1D1",
                borderRadius: calcularPx(5),
                left: calcularPx(20),
                top: calcularPx(71),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              color="#EF7B10"
              placeholder="Provincia"
              searchable={true}
              separator={true}
              noDataLabel={"No hay resultados."}
              handleKeyDownFn={({ methods, state }) => {
                let input = document.getElementById("Provincia");
                input.focus();
                if (state.values[0]) {
                  methods.clearAll();
                  input.value = "";
                }
              }}
              separatorRenderer={({ methods, state }) => {
                this.selectProvMethods = methods;
              }}
              dropdownGap={0}
              labelField={"nombre"}
              searchBy={"nombre"}
              valueField={"id"}
              options={this.state.provincias}
              onChange={(values) => this.handleChangeProvincia(values[0])}
              contentRenderer={this.customContentRenderer}
              searchFn={this.customSearch}
            />
            <Select
              className="dropPV"
              style={{
                position: "absolute",
                border: calcularPx(1) + " solid #D1D1D1",
                borderRadius: calcularPx(5),
                left: calcularPx(20),
                top: calcularPx(126),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              color="#EF7B10"
              placeholder="Localidad"
              searchable={true}
              separator={true}
              noDataLabel={"No hay resultados."}
              handleKeyDownFn={({ methods, state }) => {
                let input = document.getElementById("Localidad");
                input.focus();
                if (state.values[0]) {
                  methods.clearAll();
                  input.value = "";
                }
              }}
              separatorRenderer={({ methods }) => {
                this.selectLocMethods = methods;
              }}
              dropdownGap={0}
              labelField={"nombre"}
              searchBy={"nombre"}
              valueField={"id"}
              options={this.state.localidades}
              onChange={(values) => this.handleChangeLocalidad(values[0])}
              contentRenderer={this.customContentRenderer}
              searchFn={this.customSearch}
            />
          </div>

          {mobile && <div style={{ height: calcularPx(30), width: "100%" }} />}

          <div
            id="mapaConLista"
            style={{
              width: "auto",
              maxWidth: mobile ? calcularPx(1378) : calcularPx(1006),
              marginLeft: mobile ? "auto" : calcularPx(32),
              marginRight: "auto",
            }}
          >
            <Mapa
              verMapa={true}
              puntos={this.state.puntos}
              seleccionado={this.state.seleccionado}
              mobile={mobile}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PuntosDeVenta;
