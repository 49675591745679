import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../calcularPx.js";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import SubmenuProductos from "./submenuProductos.jsx";
import { createRef } from "react";
import { CSSTransition } from "react-transition-group";
import AccesoDesktop from "../acceso/menuAccesoDesktop.jsx";
import AccesoMobile from "../acceso/menuAccesoMobile.jsx";

class Menu extends Component {
  state = { mostrarSubmenu: false, nivelLogin: this.props.nivelLogin };

  //constructor, componentDidMount, componentWillUnmount y handleClickOutside son para ocultar el submenu de productos tocando fuera de el
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivelLogin: this.props.nivelLogin });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target))
      this.setState({ mostrarSubmenu: false });
  }

  style(left) {
    let style = {
      background: "transparent",
      border: "none",
    };
    if (this.props.mobile) {
      style.position = "relative";
      style.display = "block";
      style.width = "100%";
      style.padding = calcularPx(30) + " " + calcularPx(0);
      style.textAlign = "left";
    } else {
      style.position = "absolute";
      style.left = left;
      style.top = calcularPx(40);
      style.minWidth = calcularPx(228.13);
      style.textAlign = "left";
    }
    return style;
  }

  render() {
    const { botones, mobile, ocultar } = this.props;
    return (
      <nav
        style={{
          background: "#FFFFFF",
          paddingLeft: "3.44%",
          height: mobile ? "100vh" : "auto",
        }}
      >
        <div ref={this.ref}>
          <button
            className={"itemNavBar"}
            style={this.style("14.90%")}
            onClick={() => {
              this.setState({ mostrarSubmenu: !this.state.mostrarSubmenu });
            }}
            onMouseEnter={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenu: true });
                  }
            }
            onMouseLeave={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenu: false });
                  }
            }
          >
            PRODUCTOS
            <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="girar"
            >
              <IcoChevron
                width={calcularPx(15)}
                height={calcularPx(15)}
                style={{
                  position: mobile ? "absolute" : "static",
                  left: "90%",
                }}
              />
            </CSSTransition>
            <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              <SubmenuProductos mobile={mobile} ocultarMenu={ocultar} />
            </CSSTransition>
          </button>
        </div>

        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/puntosDeVenta"
          style={this.style("26.04%")}
        >
          PUNTOS DE VENTA
        </Link>
        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/posventa"
          style={this.style("39.27%")}
        >
          POSVENTA
        </Link>
        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/preguntasFrecuentes"
          style={this.style("48.96%")}
        >
          PREGUNTAS FRECUENTES
        </Link>
        {this.state.nivelLogin === 3 ? (
          <Link
            className={"itemNavBar"}
            onClick={ocultar}
            to="/registrarProducto"
            style={this.style("64.79%")}
          >
            REGISTRE SU PRODUCTO
          </Link>
        ) : (
          <></>
        )}

        {mobile ? (
          <AccesoMobile
            nivelLogin={this.state.nivelLogin}
            botones={botones}
            ocultar={ocultar}
          />
        ) : (
          <AccesoDesktop
            nivelLogin={this.state.nivelLogin}
            botones={botones}
            ocultar={ocultar}
          />
        )}
      </nav>
    );
  }
}

/*
<Link
  className={"itemNavBar"}
  onClick={ocultar}
  to="/servicioTecnico"
  style={this.style("64.06%")}
>
  SERVICIO TÉCNICO
</Link>
        */

export default Menu;
