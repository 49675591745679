import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Select from "react-dropdown-select";
import CalculoTermico from "./calculoTermico.jsx";
import CalculoAgua from "./calculoAgua.jsx";

class Calculo extends Component {
  state = { seleccionado: 0 }; //0 - seleccionar, 1 - térmico, 2 - agua

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  customContentRenderer({ props }) {
    return (
      <React.Fragment>
        <div
          className="selectCalculo"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id="selectGeneral"
            readOnly={true}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobileBanner =
      window.innerWidth < parseInt(calcularPx(650).slice(0, -2));
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: calcularPx(203),
            maxHeight: "200px",
            textJustify: "left",
          }}
        >
          <img
            src={require("../../res/banners/calculo.jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100vw",
              height: "auto",
              minHeight: calcularPx(203),
              maxHeight: "200px",
              zIndex: -1,
            }}
          />
          <h1
            className={mobileBanner ? "textoBannerMobile" : "textoBanner"}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0%,-50%)",
            }}
          >
            Cálculo térmico y de agua
          </h1>
        </div>

        <div style={{ height: calcularPx(64), width: "100%" }} />

        <div
          style={{
            position: "relative",
            width: calcularPx(340),
            height: calcularPx(175),
            margin: "auto",
            border: calcularPx(1) + " solid #D4D4D8",
            borderRadius: calcularPx(5),
            boxShadow: "0px 0px " + calcularPx(4) + " rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1
            className="selectCalculo"
            style={{
              position: "absolute",
              left: calcularPx(24),
              top: calcularPx(33),
            }}
          >
            Seleccioná el tipo de cálculo
          </h1>
          <Select
            className="selectCalculo"
            style={{
              position: "absolute",
              border: calcularPx(1) + " solid #D1D1D1",
              borderRadius: calcularPx(5),
              left: calcularPx(20),
              top: calcularPx(76),
              width: calcularPx(300),
              height: calcularPx(43),
            }}
            searchable={false}
            placeholder="Seleccionar"
            dropdownGap={0}
            color="#EF7B10"
            options={[
              { label: "Cálculo térmico", value: 1 },
              { label: "Cálculo de agua", value: 2 },
            ]}
            onChange={(values) => {
              document.getElementById("selectGeneral").value = values[0].label;
              this.setState({ seleccionado: values[0].value });
            }}
            contentRenderer={this.customContentRenderer}
          />
        </div>

        {this.state.seleccionado == 1 ? (
          <CalculoTermico />
        ) : this.state.seleccionado == 2 ? (
          <CalculoAgua />
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }
}

export default Calculo;
