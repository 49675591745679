import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Administracion from "../administracion/paginaAdministracion.jsx";
import Sitio from "./Sitio";

class App extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={<Administracion />} />
          <Route path="*" element={<Sitio />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
