import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";

class CalculoAgua extends Component {
  state = {
    gas: false,
    personas: -1,
    abastecimiento: -1,
    resultado: null,
  };

  customContentRenderer({ props }) {
    return (
      <React.Fragment>
        <div
          className="selectCalculo"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id={props.placeholder}
            readOnly={true}
            placeholder={"Seleccionar"}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  calcular() {
    const { gas, personas, abastecimiento } = this.state;

    fetch(
      "/api/calculo/agua/?gas=" +
        gas +
        "&personas=" +
        personas +
        "&abastecimiento=" +
        abastecimiento
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ resultado: data });
      });
  }

  getTipo(tipos) {
    let retorno = "";
    let nombres = {
      g: "a gas",
      e: "eléctrico",
    };
    Object.keys(tipos).forEach((t) => {
      if (tipos[t]) {
        retorno += " o " + nombres[t];
      }
    });
    return retorno.slice(3);
  }

  render() {
    const mobile = window.innerWidth < parseInt(calcularPx(785).slice(0, -2));
    const resultado = this.state.resultado;
    return (
      <React.Fragment>
        <div style={{ height: calcularPx(36), width: "100%" }} />
        <div
          style={{
            position: "relative",
            width: calcularPx(340),
            height: "auto",
            margin: "auto",
            border: calcularPx(1) + " solid #D4D4D8",
            borderRadius: calcularPx(5),
            boxShadow: "0px 0px " + calcularPx(4) + " rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1
            className="tituloCalculo"
            style={{
              position: "relative",
              paddingTop: calcularPx(44),
              paddingLeft: "6%",
              display: "block",
            }}
          >
            Cálculo de agua
          </h1>

          <div
            style={{
              paddingLeft: "6%",
              paddingRight: "6%",
            }}
          >
            <h1
              className="selectCalculo"
              style={{
                position: "relative",
                paddingTop: calcularPx(24),
              }}
            >
              ¿Tenés conexión a gas?
            </h1>
            <div
              className="selectCalculo"
              onChange={(event) =>
                this.setState({ gas: event.target.value, resultado: null })
              }
            >
              <input type="radio" value={true} name="gas" />
              Si
              <input
                type="radio"
                value={false}
                defaultChecked
                name="gas"
                style={{ marginLeft: calcularPx(44) }}
              />
              No
            </div>

            <h1
              className="selectCalculo"
              style={{
                position: "relative",
                paddingTop: calcularPx(45),
              }}
            >
              ¿Cantidad de personas?
            </h1>
            <Select
              className="selectCalculo"
              style={{
                position: "relative",
                border: calcularPx(1) + " solid #D1D1D1",
                borderRadius: calcularPx(5),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              searchable={false}
              placeholder="termico"
              dropdownGap={0}
              color="#EF7B10"
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4 o más", value: 4 },
              ]}
              onChange={(values) => {
                document.getElementById("termico").value = values[0].label;
                this.setState({
                  personas: values[0].value,
                  resultado: null,
                });
              }}
              contentRenderer={this.customContentRenderer}
            />

            <h1
              className="selectCalculo"
              style={{
                position: "relative",
                paddingTop: calcularPx(45),
              }}
            >
              ¿Cantidad de abastecimiento simultáneo?
            </h1>
            <Select
              className="selectCalculo"
              style={{
                position: "relative",
                border: calcularPx(1) + " solid #D1D1D1",
                borderRadius: calcularPx(5),
                width: calcularPx(300),
                height: calcularPx(43),
              }}
              searchable={false}
              placeholder="abastecimiento"
              dropdownGap={0}
              color="#EF7B10"
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4 o más", value: 4 },
              ]}
              onChange={(values) => {
                document.getElementById("abastecimiento").value =
                  values[0].label;
                this.setState({
                  abastecimiento: values[0].value,
                  resultado: null,
                });
              }}
              contentRenderer={this.customContentRenderer}
            />

            <button
              className="botonCalculo"
              disabled={
                this.state.personas == -1 || this.state.abastecimiento == -1
              }
              onClick={() => this.calcular()}
              style={{
                width: calcularPx(300),
                height: calcularPx(38),
                marginTop: calcularPx(45),
              }}
            >
              Calcular
            </button>

            {resultado && (
              <div
                className="textoResultado"
                style={{
                  border: calcularPx(1) + " solid #D4D4D8",
                  borderRadius: calcularPx(5),
                  width: "75%",
                  height: "auto",
                  padding: calcularPx(10),
                  textAlign: "center",
                  marginTop: calcularPx(40),
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {"Se recomienda un termotanque " +
                  this.getTipo(resultado.tipos) +
                  " de al menos " +
                  parseInt(resultado.litros) +
                  " litros."}
              </div>
            )}
            {resultado && (
              <Link
                to={
                  "/productos/termotanquesRecomendados?litros=" +
                  parseInt(resultado.litros) +
                  "&e=" +
                  resultado.tipos.e +
                  "&g=" +
                  resultado.tipos.g
                }
                className="botonResultado"
                onClick={() => this.calcular()}
                style={{
                  position: "relative",
                  width: calcularPx(300),
                  height: calcularPx(38),
                  marginTop: calcularPx(40),
                }}
              >
                Ver productos
              </Link>
            )}
          </div>
          <div style={{ height: calcularPx(40) }} />
        </div>
      </React.Fragment>
    );
  }
}

export default CalculoAgua;
