import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Select from "react-dropdown-select";
import InputNumber from "react-input-number";
import { Link } from "react-router-dom";

class CalculoTermico extends Component {
  state = {
    dorm: false,
    clima: 2,
    ext: false,
    tipoVivienda: -1,
    largo: null,
    ancho: null,
    alto: null,
    resultado: null,
  };

  customContentRenderer({ props }) {
    return (
      <React.Fragment>
        <div
          className="selectCalculo"
          style={{ width: "100%", height: "100%", maxWidth: calcularPx(259) }}
        >
          <input
            type="text"
            id="selectTermico"
            readOnly={true}
            placeholder={props.placeholder}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  calcular() {
    const { dorm, clima, ext, tipoVivienda, largo, ancho, alto } = this.state;
    fetch(
      "/api/calculo/termico/?dorm=" +
        dorm +
        "&clima=" +
        clima +
        "&ext=" +
        ext +
        "&tipoVivienda=" +
        tipoVivienda +
        "&largo=" +
        largo +
        "&ancho=" +
        ancho +
        "&alto=" +
        alto
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ resultado: data });
      });
  }

  getTipo(tipos) {
    let retorno = "";
    let nombres = {
      sce: "sin conexión al exterior",
      tb: "de tiro balanceado",
      tbu: "de tiro balanceado en U",
    };
    Object.keys(tipos).forEach((t) => {
      if (tipos[t]) {
        retorno += " o " + nombres[t];
      }
    });
    return retorno.slice(3);
  }

  render() {
    const mobile = window.innerWidth < parseInt(calcularPx(785).slice(0, -2));
    const resultado = this.state.resultado;
    return (
      <React.Fragment>
        <div style={{ height: calcularPx(36), width: "100%" }} />
        <div
          style={{
            position: "relative",
            width: mobile ? "80vw" : calcularPx(785),
            height: "auto",
            margin: "auto",
            border: calcularPx(1) + " solid #D4D4D8",
            borderRadius: calcularPx(5),
            boxShadow: "0px 0px " + calcularPx(4) + " rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1
            className="tituloCalculo"
            style={{
              position: "relative",
              paddingTop: calcularPx(44),
              paddingLeft: "6%",
              display: "block",
            }}
          >
            Cálculo térmico
          </h1>
          <div style={{ display: mobile ? "block" : "flex" }}>
            <div
              style={{
                paddingLeft: "6%",
                maxWidth: mobile ? "auto" : "44%",
                flex: 1,
              }}
            >
              <div>
                <h1
                  className="selectCalculo"
                  style={{
                    position: "relative",
                    paddingTop: calcularPx(24),
                  }}
                >
                  ¿Se instala en dormitorio o baño?
                </h1>
                <div
                  className="selectCalculo"
                  onChange={(event) =>
                    this.setState({ dorm: event.target.value, resultado: null })
                  }
                >
                  <input type="radio" value={true} name="dorm" />
                  Si
                  <input
                    type="radio"
                    value={false}
                    defaultChecked
                    name="dorm"
                    style={{ marginLeft: calcularPx(44) }}
                  />
                  No
                </div>
              </div>

              <div>
                <h1
                  className="selectCalculo"
                  style={{
                    position: "relative",
                    paddingTop: calcularPx(45),
                  }}
                >
                  ¿Cómo es el clima de la zona?
                </h1>
                <div
                  className="selectCalculo"
                  onChange={(event) =>
                    this.setState({
                      clima: event.target.value,
                      resultado: null,
                    })
                  }
                >
                  <input type="radio" value={2} defaultChecked name="clima" />
                  Templado
                  <input
                    type="radio"
                    value={1}
                    name="clima"
                    style={{
                      marginLeft: calcularPx(20),
                    }}
                  />
                  Frío
                  <input
                    type="radio"
                    value={0}
                    name="clima"
                    style={{ marginLeft: calcularPx(20) }}
                  />
                  Muy frío
                </div>
              </div>
            </div>

            <div
              style={{
                paddingLeft: "6%",
                maxWidth: mobile ? "auto" : "44%",
                flex: 1,
              }}
            >
              <div>
                <h1
                  className="selectCalculo"
                  style={{
                    position: "relative",
                    paddingTop: mobile ? calcularPx(45) : calcularPx(24),
                  }}
                >
                  ¿El ambiente tiene pared al exterior?
                </h1>
                <div
                  className="selectCalculo"
                  onChange={(event) =>
                    this.setState({ ext: event.target.value, resultado: null })
                  }
                >
                  <input type="radio" value={true} name="ext" />
                  Si
                  <input
                    type="radio"
                    value={false}
                    defaultChecked
                    name="ext"
                    style={{ marginLeft: calcularPx(44) }}
                  />
                  No
                </div>
              </div>

              <div>
                <h1
                  className="selectCalculo"
                  style={{
                    position: "relative",
                    paddingTop: calcularPx(45),
                  }}
                >
                  ¿Qué tipo de vivienda es?
                </h1>
                <Select
                  className="selectCalculo"
                  style={{
                    position: "relative",
                    border: calcularPx(1) + " solid #D1D1D1",
                    borderRadius: calcularPx(5),
                    width: calcularPx(300),
                    height: calcularPx(43),
                  }}
                  searchable={false}
                  placeholder="Seleccionar"
                  dropdownGap={0}
                  color="#EF7B10"
                  options={[
                    { label: "Departamento interior", value: 0 },
                    { label: "Departamento en altura con linderos", value: 1 },
                    { label: "Departamento en altura sin linderos", value: 2 },
                    { label: "Casa con linderos", value: 3 },
                    { label: "Casa sin linderos", value: 4 },
                  ]}
                  onChange={(values) => {
                    document.getElementById("selectTermico").value =
                      values[0].label;
                    this.setState({
                      tipoVivienda: values[0].value,
                      resultado: null,
                    });
                  }}
                  contentRenderer={this.customContentRenderer}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              paddingLeft: "6%",
              paddingRight: "6%",
            }}
          >
            <h1
              className="selectCalculo"
              style={{ paddingTop: calcularPx(44) }}
            >
              ¿Qué tamaño tiene el ambiente?
            </h1>
            <div style={{ height: calcularPx(25) }} />
            <div
              className="selectCalculo"
              style={{ display: mobile ? "block" : "flex" }}
            >
              <div
                style={{
                  flex: 1,
                  display: "block",
                  width: "auto",
                  maxWidth: mobile ? "auto" : "33.3%",
                }}
              >
                Largo (en metros)
                <InputNumber
                  style={{
                    display: "block",
                    border: calcularPx(1) + " solid #D4D4D8",
                    borderRadius: calcularPx(5),
                    marginTop: calcularPx(6),
                    marginBottom: calcularPx(10),
                    width: calcularPx(210),
                    height: calcularPx(43),
                  }}
                  min={0}
                  max={99999}
                  step={0.01}
                  value={this.state.largo}
                  onChange={(value) =>
                    this.setState({ largo: value, resultado: null })
                  }
                  enableMobileNumericKeyboard
                />
              </div>
              <div
                style={{
                  flex: 2,
                  display: "block",
                  width: "auto",
                  maxWidth: mobile ? "auto" : "33.3%",
                }}
              >
                Ancho (en metros)
                <InputNumber
                  style={{
                    display: "block",
                    border: calcularPx(1) + " solid #D4D4D8",
                    borderRadius: calcularPx(5),
                    marginTop: calcularPx(6),
                    marginBottom: calcularPx(10),
                    width: calcularPx(210),
                    height: calcularPx(43),
                  }}
                  min={0}
                  max={99999}
                  step={0.01}
                  value={this.state.ancho}
                  onChange={(value) =>
                    this.setState({ ancho: value, resultado: null })
                  }
                  enableMobileNumericKeyboard
                />
              </div>
              <div
                style={{
                  flex: 3,
                  display: "block",
                  width: "auto",
                  maxWidth: mobile ? "auto" : "33.3%",
                }}
              >
                Alto (en metros)
                <InputNumber
                  style={{
                    display: "block",
                    border: calcularPx(1) + " solid #D4D4D8",
                    borderRadius: calcularPx(5),
                    marginTop: calcularPx(6),
                    marginBottom: calcularPx(10),
                    width: calcularPx(210),
                    height: calcularPx(43),
                  }}
                  min={0}
                  max={99999}
                  step={0.01}
                  value={this.state.alto}
                  onChange={(value) =>
                    this.setState({ alto: value, resultado: null })
                  }
                  enableMobileNumericKeyboard
                />
              </div>
            </div>
          </div>
          <button
            className="botonCalculo"
            disabled={
              this.state.tipoVivienda == -1 ||
              this.state.largo == 0 ||
              this.state.ancho == 0 ||
              this.state.alto == 0
            }
            onClick={() => this.calcular()}
            style={{
              width: calcularPx(300),
              height: calcularPx(38),
              marginTop: calcularPx(45),
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Calcular
          </button>

          {resultado && (
            <div
              className="textoResultado"
              style={{
                border: calcularPx(1) + " solid #D4D4D8",
                borderRadius: calcularPx(5),
                width: "75%",
                height: "auto",
                padding: calcularPx(10),
                textAlign: "center",
                marginTop: calcularPx(40),
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {"Se recomienda un calefactor de al menos " +
                parseInt(resultado.kcal) +
                " kcal, " +
                this.getTipo(resultado.tipos) +
                "."}
            </div>
          )}
          {resultado && (
            <Link
              to={
                "/productos/calefactoresRecomendados?kcal=" +
                parseInt(resultado.kcal) +
                "&sce=" +
                resultado.tipos.sce +
                "&tb=" +
                resultado.tipos.tb +
                "&tbu=" +
                resultado.tipos.tbu
              }
              className="botonResultado"
              onClick={() => this.calcular()}
              style={{
                position: "relative",
                width: calcularPx(300),
                height: calcularPx(38),
                marginTop: calcularPx(40),
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Ver productos
            </Link>
          )}

          <div style={{ height: calcularPx(40) }} />
        </div>
      </React.Fragment>
    );
  }
}

export default CalculoTermico;
