import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Pregunta from "./pregunta.jsx";

class PreguntasFrecuentes extends Component {
  state = { preguntas: [] };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/preguntasFrecuentes")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ preguntas: data });
      });
  }

  render() {
    const mobileBanner =
      window.innerWidth < parseInt(calcularPx(650).slice(0, -2));
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: calcularPx(203),
            maxHeight: "200px",
            textJustify: "left",
          }}
        >
          <img
            src={require("../../res/banners/preguntasFrecuentes.jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100vw",
              height: "auto",
              minHeight: calcularPx(203),
              maxHeight: "200px",
              zIndex: -1,
            }}
          />
          <h1
            className={mobileBanner ? "textoBannerMobile" : "textoBanner"}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0%,-50%)",
            }}
          >
            Preguntas frecuentes
          </h1>
        </div>

        <div
          style={{
            position: "relative",
            paddingTop: "3.65vw",
            paddingLeft: "8.33%",
            paddingRight: "8.33%",
          }}
        >
          {this.state.preguntas.map((e) => (
            <Pregunta pregunta={e.pregunta} respuesta={e.respuesta} />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default PreguntasFrecuentes;
