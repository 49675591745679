import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../general/calcularPx.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BannersMobile = ({ banners }) => {
  const responsive = {
    todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
  };
  return (
    <React.Fragment>
        <div 
          style={{ position: "relative",
            maxWidth: "100%",
            zIndex: 0,
            overflow: "hidden" 
            }}
        >
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={false}
            infinite={true}
            removeArrowOnDeviceType={["todo"]}
            autoPlay={true}
          >
            {banners.primero
              .filter((e) => e.plataforma == 1 || e.plataforma == 2)
              .map((e, i) => {
                return (
                  <Link to={e.link}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: calcularPx(200),
                        maxHeight: calcularPx(800),
                      }}
                    >
                      <img
                        alt={"banner1-" + i}
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                );
              })}
          </Carousel>
        </div>
      <div
        style={{
          position: "relative",
          maxWidth: "93.12%",
          left: "3.44%",
          zIndex: 0,
        }}
      >
        <div style={{ height: calcularPx(25) }} />

        <div style={{ borderRadius: "0.375rem", overflow: "hidden" }}>
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={false}
            infinite={true}
            removeArrowOnDeviceType={["todo"]}
            autoPlay={true}
            autoPlaySpeed={4500}
          >
            {banners.segundo
              .filter((e) => e.plataforma == 1 || e.plataforma == 2)
              .map((e, i) => {
                return (
                  <Link to={e.link}>
                    <div>
                      <img
                        alt={"banner2-" + i}
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                );
              })}
          </Carousel>
        </div>

        <div style={{ height: calcularPx(25) }} />

        <div style={{ borderRadius: "0.375rem", overflow: "hidden" }}>
          <Carousel
            responsive={responsive}
            showDots={false}
            swipeable={false}
            draggable={false}
            infinite={true}
            removeArrowOnDeviceType={["todo"]}
            autoPlay={false}
          >
            {banners.tercero
              .filter((e) => e.plataforma == 1 || e.plataforma == 2)
              .map((e, i) => {
                return (
                  <Link to={e.link}>
                    <div>
                      <img
                        alt={"banner3-" + i}
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                );
              })}
          </Carousel>
        </div>

        <div style={{ height: calcularPx(25) }} />

        <div style={{ borderRadius: "0.375rem", overflow: "hidden" }}>
          <Carousel
            responsive={responsive}
            showDots={false}
            swipeable={false}
            draggable={false}
            infinite={true}
            removeArrowOnDeviceType={["todo"]}
            autoPlay={false}
          >
            {banners.cuarto
              .filter((e) => e.plataforma == 1 || e.plataforma == 2)
              .map((e, i) => {
                return (
                  <Link to={e.link}>
                    <div>
                      <img
                        alt={"banner4-" + i}
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                );
              })}
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BannersMobile;
