import {
  Datagrid,
  List,
  BooleanField,
  TextField,
  Edit,
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  ImageInput,
  ImageField,
  EditButton,
  required,
} from "react-admin";

import { CustomImageField } from "../herramientas/customImageField";

const filtros = [
  <SelectInput
    source="seccion"
    choices={[
      { id: "Institucional", name: "Institucional" },
      { id: "Línea Patagonia", name: "Línea Patagonia" },
      { id: "Línea Euro", name: "Línea Euro" },
      { id: "Termotanques a gas", name: "Termotanques a gas" },
      { id: "Termotanques eléctricos", name: "Termotanques eléctricos" },
      { id: "Hornos eléctricos", name: "Hornos eléctricos" },
    ]}
  />,
  <BooleanInput source="portada" />,
];

export const zonaClientesList = () => (
  <List title={"Zona Clientes"} filters={filtros}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="filePath.rawFile.name" label="Archivo" />
      <TextField source="seccion" />
      <BooleanField source="portada" looseValue={true} />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm title="Zona Clientes">
    <ImageInput
      source="filePath"
      label="Archivo"
      placeholder={
        <p style={{ textAlign: "left" }}>
          Arrastre una imágen o un archivo .pdf, o haga click
        </p>
      }
      accept="image/*,.pdf"
      validate={[required]}
    >
      <CustomImageField source="src" validate={[required]} />
    </ImageInput>
    <SelectInput
      source="seccion"
      emptyValue={"Institucional"}
      emptyText={"Institucional"}
      choices={[
        { id: "Línea Patagonia", name: "Línea Patagonia" },
        { id: "Línea Euro", name: "Línea Euro" },
        { id: "Termotanques a gas", name: "Termotanques a gas" },
        { id: "Termotanques eléctricos", name: "Termotanques eléctricos" },
        { id: "Hornos eléctricos", name: "Hornos eléctricos" },
      ]}
      validate={[required]}
    />
    <BooleanInput
      source="portada"
      label="Portada (sólo válido para imágenes)"
    />
  </SimpleForm>
);

export const zonaClientesEdit = () => <Edit>{form}</Edit>;

export const zonaClientesCreate = () => <Create>{form}</Create>;
