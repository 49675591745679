import {
  Edit,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  ImageInput,
  required,
} from "react-admin";

import { CustomImageField } from "../herramientas/customImageField";

const filtros = [
  <TextInput source="nombre" label="Nombre exacto" />,
  <TextInput source="modelo" label="Modelo exacto" />,
  <SelectInput
    source="tiraje"
    label="Tipo"
    choices={[
      { id: "0", name: "Sin conexión al exterior" },
      { id: "1", name: "Tiro balanceado" },
      { id: "3", name: 'Tiro balanceado (o en "U")' },
    ]}
  />,
  <BooleanInput source="activo" label="Activo (visible en la página)" />,
];

export const calefactoresList = () => (
  <List filters={filtros} title={"Calefactores"}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="nombre" />
      <TextField source="modelo" />
      <NumberField source="kcal" />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <BooleanInput source="activo" label="Activo (visible en la página)" />
    <TextInput source="nombre" validate={[required]} />
    <TextInput source="modelo" validate={[required]} />
    <TextInput
      multiline
      fullWidth
      source="descripcion"
      label="Descripción"
      defaultValue={""}
    />
    <ImageInput
      source="imgPath"
      label="Imagen"
      placeholder={
        <p style={{ textAlign: "left" }}>Arrastre una imágen o haga click</p>
      }
      accept="image/*"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <ImageInput
      source="manualPath"
      label="Manual"
      placeholder={
        <p style={{ textAlign: "left" }}>
          Arrastre una imágen o un archivo .pdf, o haga click
        </p>
      }
      accept="image/*,.pdf"
      validate={[required]}
    >
      <CustomImageField source="src" />
    </ImageInput>
    <NumberInput source="kcal" validate={[required]} />
    <NumberInput source="altoCm" label="Alto en Cm" validate={[required]} />
    <NumberInput source="anchoCm" label="Ancho en Cm" validate={[required]} />
    <NumberInput
      source="profundidadCm"
      label="Prof. en Cm"
      validate={[required]}
    />
    <NumberInput
      source="volumenCalefaccionM3"
      label="Volumen de calefacción en M3"
      validate={[required]}
    />
    <BooleanInput source="sce" label="Sin conexión al exterior" />
    <BooleanInput source="tb" label="Tiro balanceado" />
    <BooleanInput source="tbu" label='Tiro balanceado en "U"' />
    <BooleanInput source="salidaConcentrica" label="Salida concéntrica" />
    <BooleanInput source="movil" label="Móvil" />
  </SimpleForm>
);

export const calefactoresCreate = () => <Create>{form}</Create>;

export const calefactoresEdit = () => <Edit>{form}</Edit>;
