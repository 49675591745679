import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import { Link, useLocation } from "react-router-dom";

function withLocation(Component) {
  function ComponentWithLocation(props) {
    let location = useLocation();
    return <Component {...props} location={location} />;
  }
  return ComponentWithLocation;
}

class Producto extends Component {
  state = { url: null };

  async componentDidMount() {
    const res = await fetch(this.props.producto.imgPath);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    this.setState({ url: url });
    this.props.setUrl(url);
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.url);
  }

  render() {
    const { id, modelo, nombre } = this.props.producto;
    const { search } = this.props.location;
    return (
      <Link
        to={"./" + modelo.replace("/", "-") + "/" + id + search}
        className="tarjetaProducto"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: calcularPx(265),
          height: calcularPx(420),
        }}
      >
        <div
          style={{
            width: "100%",
            height: calcularPx(350),
          }}
        >
          <img
            alt=""
            src={this.state.url}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: calcularPx(80),
            background: "#F9FAFB",
            paddingLeft: calcularPx(10),
            paddingRight: calcularPx(10),
          }}
        >
          <h1
            className="nombreProducto"
            style={{
              paddingTop: calcularPx(5),
              marginBottom: 0,
              textAlign: "left",
            }}
          >
            {modelo}
          </h1>
          <h1
            className="subnombreProducto"
            style={{
              textAlign: "left",
              paddingBottom: calcularPx(5),
            }}
          >
            {nombre}
          </h1>
        </div>
      </Link>
    );
  }
}

export default withLocation(Producto);
